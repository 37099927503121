
.fieldsetTop#invDet #row-1 {  grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr))}
.fieldsetTop#invDet #row-2 {  grid-template-columns: 50px auto 80px 200px; }
.fieldsetTop#invDet #row-button-1 {display: grid;grid-template-columns:180px 180px 180px 180px 180px 180px !important;justify-content: center;align-items: center;width: 100%;}
.fieldsetTop#invDet #row-button-2 {  
    margin: 0 calc(50% - 210px);
    display: flex; 
    flex-wrap: wrap;
}
.fieldsetTop#invDet #row-button-2 button {  width: 140px; }
.fieldsetTop#invDet #bt-1-1, .fieldsetTop#invDet #bt-1-2 { /* width: fit-content; */}
.fieldsetTop#invDet #bt-1-3 {  width: 180px; }


.prod { width: 450px; min-width: 450px !important; max-width: 350px !important;}
.comptageContent form{
    display:flex;
}

.fieldsetTop#invClo #row-11 { grid-template-columns: 140px auto 150px 150px 160px;align-items: center !important; }
.fieldsetTop#invClo #select { margin-top: -3px; }
.fieldsetTop#invClo #desc {     margin-top: 5px; }
.fieldsetTop#invClo #row-1  #bt-clo {  width: 210px;}

div#cloDiv {width: 97%;margin:0px auto 0px auto;max-height:440px; overflow: auto;}
div#cloDiv table { width: calc(100% - 20px);}
div#cloDiv table th {
    padding: 5px;
    font-size: 16px;
}
.fieldsetTop#invF #row-1 { grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr));align-items: center;}

div#cloDiv table  .Mtn_inf {color:red}
div#cloDiv table  tbody .Mtn_sup {color:green}


div#cloDiv table td:nth-child(1) { width: 200px !important; }
div#cloDiv table td:nth-child(2),div#cloDiv table th:nth-child(2) { text-align: left; }
div#cloDiv table td:nth-child(3) { width: 200px !important; }
div#cloDiv table td { 
    padding: 1px 4px;
    font-size: 15px;
    font-weight: normal;
}
div#cloDiv td.detContainer { display: flex; }
 
div#cloDiv div.stockRow {
    margin: 0px 0px 0px auto;
    width: 130px;
    background: #a5a2a2;
    color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 25px 80px 25px;
}
div#cloDiv div.dtInput input {text-align: right; }
div#cloDiv table tr.stockRow {   background: #f0f0f0; }
div#cloDiv table tr.prodRow {   
    background: #3D3D3D;
    color: white;
 }
div#cloDiv table tr.stockRow td {   font-size: 13px; }
div#cloDiv div.dtInput {
    border: 0;
    text-decoration: none;
    height: 20px;
    width: 100px;
    background:white;
    border-radius: 10px;
    margin:auto 2px
}
.data .MuiInput-underline:before{
 border-bottom: none !important;
}
span.qteStock { 
    vertical-align: super;
    margin-top: 4px;
}

div#impDiv { display: flex; }  
div#impDiv table {
    width: calc( 100% - 50px);
    margin: 10px;
}
#titleImpDiv {width: 100%;text-align: start;position: relative;top: 0;left: 20px;}
span#titleSpan {font-weight: bold;}
#sectionAimprimer{ text-align: center; }

.comptageError {
 background: rgb(213, 180, 180) !important;;
 border-width: 2px !important;
 border-color: red !important;
}

.hoverstyle:hover{
    color: var(--primary-light);
    background-color: var(--secondary-light) !important;
    transition-duration: 0.15s !important;
    transition-property: 'background-color'!important;
    border-bottom-color: "black"!important;
    outline-style: solid !important;
    outline-width: 1px !important;
    outline-color: black!important;
}
#iconsInv #row-1 {  grid-template-columns:  20px 20px ; grid-gap: 5px;}
#iconsInvtab {  grid-template-columns: 50% 50% ; grid-gap: 5px;display: grid;}
.searchAdd2{ width: 50%;height: min-content;display: grid;grid-template-columns: 69% 30%;   grid-gap: 10px;}
#divName{max-height: 420px;overflow: auto;margin-top: 10px;}

#div-clo-fin{display: flex;grid-gap: 10px;align-items: center;width: 95%;margin:10px auto 10px auto;}

@media only screen and (min-width: 150px) and (max-width: 850px) {
    .fieldsetTop#invDet #row-1 {  grid-gap:3px; grid-template-columns: repeat(auto-fit, minmax(120px,1fr))}
    .fieldsetTop#invDet #row-button-1{margin-top: 0px ;}
    .fieldsetTop#invDet#invDet #row-2,.fieldsetTop#invDet#invF #row-1,.fieldsetTop#invClo #row-11{ grid-gap: 10px; 
          grid-template-columns: repeat(auto-fit, minmax(160px,1fr)) ;justify-content: center;}
    .fieldsetTop#invDet #row-button-1 {display: grid;grid-template-columns: repeat(auto-fit, minmax(200px,1fr)) !important;justify-content: center;align-items: center;width: 100%;}
    #divName{max-height:150px;overflow-y:auto ;}
}