.form-two-grid-container {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-gap: 10px;
    padding: 10px;
    margin: 0;
}
.manForm{
    display: block;
    width:100%
}
#retsuiv{
    grid-gap: 20px;
}
#retsuiv #row-1 {  grid-template-columns: repeat(auto-fit, minmax(150px,1fr)); grid-gap: 10px;}
#icons {  display:grid;}
#icons #row-1 {  grid-template-columns:  20px 20px 20px 20px; grid-gap: 5px;}
#icons #row-11 {  grid-template-columns:  20px 20px 20px; grid-gap: 5px;}
#icons #row-2 {  grid-template-columns:  20px 20px ; grid-gap: 5px;}
#icons #row-3 {  grid-template-columns:  20px 20px 20px 20px 20px 20px ; grid-gap: 5px;}

.titleCard{
   color: var(--secondary-dark);
   margin:10px;
   margin-bottom: 0px;
   font-size: 13px;
   font-weight: bold;
}
.note{
    width:100%;
    height: 100%;
    padding: 10px 25px;
    align-self:center !important;
}

#fieldSet #ret-det-info{display: grid;grid-template-columns: 37% 27% 25%;align-items: center;justify-content: center;font-size: 12px;}
#fieldSet #ret-det-info #item{display: flex;grid-gap: 10px;align-items: center;font-size: 12px;margin-bottom: 5px;}


@media only screen and (min-width: 150px) and (max-width: 520px) {
    .form-two-grid-container {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
        justify-content: center;
    }
}
@media only screen and (min-width: 150px) and (max-width: 720px) {
    #retsuiv #row-1 {  display: grid;grid-gap: 10px;grid-template-columns: repeat(auto-fit, minmax(150px,1fr));justify-content: center;}
    #fieldSet #ret-det-info{display: grid;grid-template-columns: repeat(auto-fit, minmax(180px,1fr));align-items: center;justify-content: center;font-size: 12px;}
    #fieldSet #ret-det-info #item{display: flex;grid-gap: 10px;grid-template-columns: repeat(auto-fit, minmax(180px,1fr));align-items: center;font-size: 12px;margin-bottom: 5px;}

}

