#vers #row-1 {  margin-bottom: 0 !important;}
#vers #row-1 {  grid-template-columns:auto 15% 20% 15% 25%;grid-gap:5px}
#vers #row-2 {  grid-template-columns: 40% auto; align-items:top ;grid-gap:5px}
#vers #row-2 #btn{ width:100%;height: 100%; padding:7px}
#vers #row-1 #btn{ width:100%;height: 100%; padding:7px}

#nvbn{ 
  display: grid;
}
#nvbn #row-1 { grid-template-columns:auto 25% ;grid-gap:5px}
.without-padding label {
    transform: translate(14px, 7px) scale(1);
  }
  
.without-padding div {
    padding: 0px !important;
}
#iconsAch {  display:grid;}
#iconsAch #row-1 {  grid-template-columns:  20px 20px; grid-gap: 5px;}
#iconsAch #row-2 {  grid-template-columns:  20px 20px 20px; grid-gap: 5px;}

#bonDT {
  min-height:240px !important;
  max-height:240px !important;
}
#bonDT .rdt_TableBody{
  max-height:150px !important;
}
#bonDT2 .rdt_TableBody{
  max-height:150px !important;
  overflow-y: auto;
}
#bonDT .sc-kfzBvY{
  max-height:150px !important;
  min-height:150px !important;
}
#versDT .rdt_TableBody{
  max-height:225px !important;
  overflow: scroll;
}
#versDT .sc-hKgJUU:first-child {border-right: none !important;margin-right: 0px !important;}
#versDT2 .sc-hKgJUU:first-child {border-right: none !important;margin-right: 0px !important;}
#versDT2 .rdt_TableBody{
  max-height:225px !important;
  overflow-y: auto;
}
#versDT .sc-kfzBvY{
  max-height:220px !important;
  min-height:220px !important;
}
#achatDT .rdt_TableBody{
  max-height:115px !important;
}
#achatDT2 .rdt_TableBody{
  max-height:115px !important;
  overflow: auto;
}
#achatDT .sc-kfzBvY{
  max-height:135px !important;
  min-height:135px !important;
}
.crnsTotal{
   width:fit-content;
   padding:3px 5px;
   margin:5px auto;
   background:var(--secondary-main) !important;
}
#ach_trt{padding-left: 15px;padding-right: 15px;}

.buttOnfit{
  height: fit-content;
}

.table-scroll{ max-height:200px;overflow:auto;overflow-x: hidden;margin-bottom: 5px;}
.row-expenderr #print{
  float: right;margin-bottom: 5px;
}

#selectMonit{ margin-left: auto;}
#selectMonit .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
  padding: 1px !important;
}

#prd-img{align-items: center !important;display: grid;grid-template-columns: 15% auto;grid-gap: 5px;}
#full-prod{width:100% !important;text-align:start !important;}

.flex-row{display: flex;grid-gap: 10px;align-items: center;}

#stck-row1{display: grid; grid-template-columns:65% 34%; grid-gap:10px;align-items: middle !important;}

.row-expenderr #frns-expend{width:75%;display: grid;grid-template-columns: 35% 30%;grid-gap: 70px;align-items: center;
                 justify-content: center;margin: auto;justify-content: center;}
.row-expenderr #frns-expend #item{display: flex;grid-gap: 15px;color: black;font-weight: bold;align-items: center;}
.fieldsetStock .searchWrapper{border-radius: 10px;}

#fieldSet #trt-row-1{display: grid;grid-template-columns: 32% 32% 32%;grid-gap: 10px;justify-content: center;align-items: center;margin-bottom: 5px;}
#fieldSet #trt-row-2{display: grid;grid-template-columns: 60% 20% 16%;grid-gap: 10px;justify-content: center;align-items: center;margin-bottom: 5px;}
#fieldSet #rac-row-1{display: grid;grid-template-columns: 19% 19% 19% 19% 19%;grid-gap: 10px;justify-content: center;align-items: center;margin-bottom: 5px;}
#fieldSet #ajust-row1{display: grid;grid-template-columns:48% 48%;grid-gap: 10px;justify-content: center;align-items: center;margin-bottom: 5px;}

#fieldSet #ligne1{display: grid;grid-template-columns:32% 32% 32%;grid-gap:10px;justify-content: center;align-items: center;margin-bottom: 5px;}
#fieldSet #ligne1 #item{display: flex;grid-gap:5px;justify-content: center;align-items: center;font-size: 12px;}

#fieldSet .flex-row{display: grid;grid-template-columns: 80% 20%;grid-gap: 10px;justify-content: center;align-items: center;padding: 5px;}
.flex-row .searchWrapper{border-radius: 10px;height: fit-content;min-height: 30px !important;border: 1 px solid gray !important;}

#tab-crnc{max-height: 500px;overflow: auto;}

@media only screen and (min-width: 150px) and (max-width: 850px) {
  #versDiagCont{
    overflow: auto !important;
  }
  #nvbn #row-1, #vers #row-1{ grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
}
@media only screen and (min-width: 150px) and (max-width: 700px) {
  #vers #row-2{ grid-gap: 10px; grid-template-columns: repeat(1,1fr);justify-content: center;} 
}
@media only screen and (min-width: 150px) and (max-width: 500px) {
  .flex-row,#fieldSet #trt-row-1,#fieldSet #trt-row-2{display: grid; grid-template-columns: repeat(auto-fit, minmax(180px,1fr)) !important;
          text-align: center;}
  #fieldSet #trt-row-1{grid-gap: 2px !important;}
  #fieldSet #trt-row-2{margin-top: 15px  !important;}
  #stck-row1{display:block;}
}