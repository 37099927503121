#topV #row-1 {grid-template-columns: 14% 14% 16% 16% auto 110px 70px ;}
.row-tables{display: flex;width:100%; margin-bottom: 5px;}
.row-tables #table-1{width: 50%;}
.row-tables #tablee-1{width: 100%;}
.row-tables #table-2{width: 50%;}
.row-tables #tablee-2{width: 0%;}
.fieldsetTop #row_button{width:50%;display: flex;margin-top: 15px;position:relative;left: 85%;}
.fieldsetTop  #row-button #bouton{ width: 140px}

#topC #row-1 {grid-template-columns: 14% 14% 25% auto 110px 70px }
 

#Noc #row-1{grid-template-columns: 45% auto 110px 70px }
#charts{width:96%;display: flex;flex-wrap: wrap;margin-left: 30px;margin-bottom: 15px;}
 
#charts #charts-1{width:48%;margin-left: auto;margin-right: auto;}

#charts #charts-2{width:48%;margin-left: auto;margin-right: auto;}

#charts .titleCharts{text-align: center; color:rgb(65, 63, 63);font-weight:bold;margin-left: 15px;}
#charts #charts-3{width:100%;padding-top: 15px;}

#Nov #row-1{grid-template-columns: 25% 25% auto 110px 70px }

#pie{width:98%;display: flex;margin-left:40px;margin-bottom: 15px;}
#pie #charts1{width:46%;height:90%;}
#pie #charts1 #SubChart {width: 100%; height:auto;display: flex;flex-wrap: wrap;}
#pie #charts1 #SubChart #charts-1{width:fit-content;height: fit-content ;margin-left: auto;margin-right: auto;}
#pie #charts1 .titleChart{width: 100%;color:rgb(65, 63, 63);font-weight:bold;text-align: center;}

#vnt #row-1 {grid-template-columns: 14% 14% 16% 16% auto 110px 70px ;}
#pie1{width:94.5%;display: flex;;margin-left:40px;margin-bottom: 15px;flex-wrap: wrap;}
#pie1 #charts1-1{width:97%;height:90%;}
#pie1 #charts1-1 #SubChart1 {width:95%; height:90%;display: flex;flex-wrap: wrap;}
#pie1 #charts1-1 #SubChart1 #charts-1{width:fit-content;margin-left: auto;margin-right: auto;}
#pie1 #charts1-1 .titleChart{width: 100%;color:rgb(65, 63, 63);font-weight:bold;text-align: center;}

#trans #row-1{grid-template-columns: 30% 30% 24% 110px ;align-items:center;display: grid;}

.custom-tooltip{width:220px;height:fit-content; background-color:white; text-align: center;margin-top:5px;padding: 3px;}
.custom-tooltip .label{ font-weight:bold;}
.custom-tooltip .intro{color:#343435;}
hr.rounded {
    
    border-top: 8px solid #bbb;
    border-radius: 5px;
}
.rank{width:40px !important;max-width: 40px !important;min-width: 40px !important;}

@media only screen  and (max-width: 850px) {
    #topV #row-1,#Noc #row-1,#Nov #row-1,#vnt #row-1,#topC #row-1,#trans #row-1{
        grid-template-columns: repeat(auto-fit, minmax(160px,1fr)) !important;align-items: center !important;}
    .row-tables{display: block;width:100%; margin-bottom: 5px;overflow: hidden;}
    .row-tables #table-1{width: 100%;}
    .row-tables #table-2{width: 100%;}
    .row-tables #tablee-2{width: 100%;}
    /* Rounded border */
    hr.rounded {border-top: 8px solid #bbb;border-radius: 5px;}
    #charts{width:96%;display: block;margin-left: 5px;margin-bottom: 15px }
    #charts #charts-1{width:97%;padding-top: 5px;}
    #charts #charts-2{width:97%;padding-top: 5px;}


    #pie{width:95%;display: block;margin-left:0px;margin-bottom:0px;}
    #pie #charts1{width:98%;height:90%;}
    #pie #charts1 #SubChart {width: 100%; height:auto;display: block;}
    #pie #charts1 #SubChart #charts-1{width:fit-content;height: fit-content ;margin-left: auto;margin-right: auto;}
    #pie #charts1 .titleChart{color:rgb(65, 63, 63);font-weight:bold;width: fit-content;margin-left: auto;margin-right: auto;left: 0;}

    #pie1{width:96%;display: block;margin-left:0px;margin-bottom: 15px}
    #pie1 #charts1-1{width:97%;height:90%;}
    #pie1 #charts1-1 #SubChart1 {width:95%; height:auto;display: block;}
    #pie1 #charts1-1 #SubChart1 #charts-1{width:fit-content;margin-left: auto;margin-right: auto;}
    #pie1 #charts1-1 .titleChart{color:rgb(65, 63, 63);font-weight:bold;width: fit-content;margin-left: auto;margin-right: auto;}
}