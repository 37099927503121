#histoBinars #row-1{
   grid-template-columns: minmax(150px,250px) minmax(150px,250px) 160px;
}
.binarsHead{
   display:grid;
   grid-template-columns:auto 40px;
   justify-content:center;
   align-items:center;
   grid-gap:20px;
}

#binarsHistoBarPaper{
   width:100%;
   background-color: var(--secondary-light);
   color:black ;
   display: grid;
   grid-template-columns: auto 20px;
   padding: 2px 4px;
   gap:3px;
   height: fit-content;
   align-items: center;
}
#binarsHistoBar{
   width:100%;
   background-color: var(--secondary-light);
   color:black ;
   display: grid;
   grid-template-columns: repeat(2,1fr);
   padding: 2px 4px;
   gap:3px;
   height: fit-content;
   align-self: center;
}
#binarsHistoBar p{
   font-size: 13px;
   font-weight: bold;
}
#realBinBin{
   cursor: pointer;
}
.atVals{
 display: grid;
 grid-template-columns: 15px auto;
 column-gap: 10px;
 align-items: center;
}
#addAt{
   display: flex;
   gap: 5px;
   align-items: center;
   width: fit-content;
   flex-wrap: wrap;
}
#addAt .child{
   min-width: 100px;
   max-width: 150px;
   width:auto
}
.button-flex-wrapper #addAtBtn{
   margin-left: auto;
}
.addBinarsButton{
   display: grid;
   grid-template-columns: 1px auto;
   align-items: center;
}

@media only screen  and (max-width: 600px) {
   
   #addAt2 { width: 100%; display: grid;grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(130px,1fr));justify-content: center;align-items: center;}
}