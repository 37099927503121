@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap&text=0123456789");
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=regular');
:root {
    /* red*/
    --primary-light: #e53935;
    --primary-main: #990000;
    --primary-dark:#791915;
    /*grey*/
    --secondary-light: #f6f6f6;
    --secondary-main: #ccc;
    --secondary-dark:#434343;
    /*green*/
    ---green-light:rgb(217, 255, 176);
  }

.containerr{
    width:100%;
    height:100%;
    position: absolute;
    top:10%;
}
.error-template {
    height: 100%;
    padding: 40px 15px;
    text-align: center;
}
.containerr .error-template p h1{
    margin-top:20px;
}
.error-template #err{
  width:300px;
  height: 300px;
}
.nosubmenu-template {
 color:var(--secondary-main) !important;
 justify-content: center;
 text-align: center;
 justify-self: center;
} 
.nosubmenu-template #err{
    width:300px;
    height: 250px;
}
.error-actions {
    margin-top:22px;
    margin-bottom:15px;
    margin-right:0;
}
.error-actions .btn{
   background-color:#990000;
   border-radius: 15px;
   padding: 10px 20px;
   color: white !important;
   font-weight: bold;
   cursor: pointer;
}
.note-container{
    width:100%;
    /*height: 16px;*/
}
.content-editable {
    min-height: 15px;
    min-width: 100%;
    max-width: 100%;
    color:unset;
    padding: 0px 5px 0px 5px;
    width:auto;
    height: auto;
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    border: none;
    box-sizing: border-box;
    display: inline-grid;
    align-content: center;
    justify-content:center;
}

.content-editable[type=number] {
    text-align: right;
    justify-content:end !important;
}

.content-editable[type=number]::-webkit-textfield-decoration-container {flex-direction: row-reverse}

.content-editable::-webkit-outer-spin-button, 
/*.content-editable::-webkit-inner-spin-button { margin-left: 10px; }*/
.textarea {
    width: 100% !important;
    display: -webkit-box;
    color:unset;
    padding: 0px 10px 0px 10px;
    width:fit-content;
    height: 1rem;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.content-editable:hover,.textarea:hover {
    min-width: 100%;
    max-width: 100%;
    background: var(--secondary-main);
}
  
.content-editable:focus {
    min-width: 100%;
    max-width: 100%;
    background-color: var(--primary-main);
    color: white !important;
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}

.content-editable-exp { min-height: 22px;min-width: 100%;max-width: 100%;color:unset;padding: 0px 15px 0px 15px;width:auto;height: auto;
    overflow: hidden;white-space: pre-line;text-overflow: ellipsis;border: none;box-sizing: border-box;display: inline-grid;
    align-content: center; border-radius: 6px;text-align: start !important;margin-top:0px !important;
    background: url('../img/edit.png') no-repeat right;background-size:12px;background-color: white !important; 
}
.content-editable-exp:focus { min-width: 100%;max-width: 100%;background-color: var(--primary-main) !important;
    color: white !important;border: 1px solid var(--primary-dark);border-radius: 5px;outline: 0;
}

.textarea:focus {
    display: block;
    position:absolute;
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    overflow: auto;
    resize: both;
    width: 150px;
    height: auto;
    max-height:500px;
    background: var(--primary-main);
    color:white;
    border-color:var(--primary-main);
    border-radius:5px;
    opacity:1;
    outline:0;
    z-index: 90000 !important;
    text-align:left
}

.content-editable1 {color:unset;padding: 0px 30px 0px 30px;width:auto;height: auto;} 
.content-editable1:hover {background: url('../img/edit.png') no-repeat right var(--secondary-main);background-size:20px ; }
.content-editable1:focus {
    background: var(--primary-main);
    color: white !important;
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
    
}

.button-flex-wrapper{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
    grid-gap: 5px;
}
.button-flex-wrapper-chg{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
    grid-gap:5px;
}
.flex{
    display: grid;grid-template-columns: 45% 60%;width:auto; align-items: center; 

   }
.header-prv{width: 98%; height: auto; background-color:#f6f6f6 !important; margin-left: 1%;margin-bottom:15px; margin-top: 5px;}
.header-prv .head{margin-left: 2%;}

.table-header .search{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    grid-gap:5px;
}
.table-header .search .MuiOutlinedInput-root,.table-header1 .search .MuiOutlinedInput-root {
    border-radius: 25px !important;
}
.table-header .search .MuiOutlinedInput-input{
    padding: 6px 14px;
}
.table-header .search .MuiOutlinedInput-notchedOutline,.table-header1 .search .MuiOutlinedInput-notchedOutline{
    border: 1px solid gray;
    background-color: #f1f1f1;
}
.table-header .search .MuiOutlinedInput-inputAdornedStart ,.table-header1 .search .MuiOutlinedInput-inputAdornedStart {
    z-index: 1000;
}
.table-header .search .MuiOutlinedInput-inputMarginDense,.table-header1 .search .MuiOutlinedInput-inputMarginDense {
    padding-top: 6px;
    padding-bottom: 4px;
}
.table-header{
    display: flex;
    width: 98%;
    flex-wrap: wrap;
    min-height:45px;
    margin:auto;
    margin-bottom: 5px;
    align-items:center;
}
.table-header1 .search{
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
}
.table-header1{
    display: flex;
    width: 94%;
    flex-wrap: wrap;
    min-height:45px;
    margin:auto;
    align-items:center;
}
.search-accord{
    float: right !important;margin-right: 10px;display: flex;align-items:flex-end;margin-bottom: 5px;
}
.info{display:'flex';}
.group{margin-right:'10%';}

.clickable{
 cursor: pointer;
}
.btn img { width: 10px;}
.fieldsetTop { margin: 10px 30px 10px 30px;background: #e6e6e6; border:none; border-radius: 10px; padding:8px;}
.fieldsetStock { margin: 0px 30px;background: #e6e6e6; border: solid 2px #585858; border-radius: 12px; padding: 5px 20px;}
/*dialog case*/
.fieldsetTop2 { margin: 5px 15px;background: #f1f1f1; border: solid 2px #585858; border-radius: 8px; padding: 5px 20px;}
.fieldsetTop3 { margin: 5px 2px;background: #f1f1f1; border: solid 1px #585858; border-radius: 8px; padding: 5px;}
.fieldsetTopTrt { margin: 5px 8px;background: #f1f1f1; border: solid 2px #585858; border-radius: 8px; padding: 5px;}
.fieldsetTop4 { margin: 5px;background: #f1f1f1; border: solid 1px #585858; border-radius: 8px; padding: 5px 20px;}
legend {
    padding: 5px;
    background: #585858;
    color: #fff;
    font-size: 90%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    font-weight: 700;
}
div.rowGrid {
    display: grid;
    margin: 0;
    align-items: center;
    grid-gap:5px;
}
span.desc{
    color:gray;
    margin-right: 5px;
}
span.inf{
    font-weight: 700;
    min-width: 65px;
    height: fit-content;
    min-height: 18px;
    padding: 0 5px;
    background-color: #d8d0d0;
    border-radius: 3px;
    box-shadow: 1px 1px 1px grey;
    margin-right: 5px;
    width:100%;
    font-size: 13px;
}
span.infSelect{
    font-weight: 700;
    min-width: 65px;
    height: fit-content;
    min-height: 16px;
    padding: 0;
    background-color: #d8d0d0;
    border-radius: 5px;
    box-shadow: 1px 1px 1px grey;
    width:100%;
    font-size: 13px;
}

#vntInfo .infSelect{
   width: 100%;
}

span.infSelect .content-editable{ 
   justify-content: start !important;
}
table.data {
    border-spacing: 0;
    width: 100%;
}
table.data thead th {
    color: white;
    background-color: black;
    padding: 1px 5px;
    font-size: 14px;
    font-weight: normal;
}
table.data td {
    font-weight: bold;
    font-size: 13px;
    text-align: center;
}
table.data tr:nth-child(even) {background:#ebebeb }
table.data tr:nth-child(odd) {background: #fff}

.grid-form {
    align-self: center;
    width:100%;
    padding: 0px;
    padding-left: 30px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(1, 500px);
    grid-gap: 30px;
    /*grid-auto-rows: minmax(22px, auto);*/
    justify-items:center;
}

.grid-wrapper {
    align-self: center;
    padding: 0px;
    padding-left: 30px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3px;
    grid-auto-rows: minmax(22px, auto);
    justify-items:center !important;
}


.nodata{width:fit-content;height:fit-content;display:grid;    justify-items: center;justify-content: center !important; align-items: center!important; margin:auto; }
#cont{width:fit-content; height: fit-content;margin: auto;}
.message { text-align: center; font-size: 30px; font-family: 'Signika', sans-serif; padding-top: 20px; }
.nodata .notFound{width:300px;height:300px; display: inline;}
.nodata .notAllowed{width:300px;height:307px; display: inline;margin-bottom:20px;padding:20px 30px}
.nodata .message{color:gray; padding:0; font-size: 20px; font-family: 'Signika', sans-serif;}

.accord-table{ padding: 0; margin: 0; width: 100%; border: none;}
.flex-wrapper { margin: 0px; padding:0px; width:100%; display: flex; flex-direction: row; align-items: center; text-align: center;flex-wrap: wrap;}
.grid-wrapper-field { display: grid; grid-template-columns: auto 1fr; padding-bottom: 5px; text-align: start;}
.grid-wrapper-field2 { display: grid; grid-template-columns: 50% calc(50% - 5px);grid-gap: 5px;}
/* Overriding element hover of multiselect*/
/* Overriding element hover of multiselect*/
.optionContainer li:hover,
.optionContainer .highlight {
  background: #ededed !important;
  color: black !important;
  font-size: 13px;
}
.hello111{
    overflow: hidden;
}
.grid2{
    display:grid;
    grid-template-columns:repeat(2,1fr);
    column-gap: 5px;
    align-items: center;}

.grid3{
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-gap:5px}

.grid4{
    display:grid;
    grid-template-columns:repeat(4,1fr);
    grid-gap:5px}
.grid4-1{
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-gap:5px}
.small {
    transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8); /* IE 9 /
    -webkit-transform: scale(0.8, 0.8); / Safari and Chrome /
    -o-transform: scale(0.8, 0.8); / Opera /
    -moz-transform: scale(0.8, 0.8); / Firefox */
  }
.div-vide{
    width:150px; 
    max-width:150px; 
    height: 15px;
    max-height: 20px;
    margin:auto ;
    display: inline-block;
}
#changePass .MuiDialogContent-root {
    overflow-y: hidden;
}
#changePass .MuiFormControl-root{background-color: #f8f8f8 !important;margin-right: 15px;}
#changePass .smallText .MuiOutlinedInput-inputMarginDense{margin-left: 10px;}

.smallSelectDT{
    width:100%;
    min-width: 130px;
    text-align:center;
    height:16px;
}
.smallSelectDate{
    width:100%;
    min-width: 90px;
    text-align:center;
    height:16px;
}

.smallSelectDT2{
    width:100%;
    min-width: 120px;
    text-align:start;
    height:16px;
}
.smallSelectDTIcon{
    width:100%;
    min-width: 110px;
    text-align:center;
   /* height:16px;*/
}

.smallSelectDT .MuiInputBase-root,.smallSelectDT2 .MuiInputBase-root,.smallSelectDTT .MuiInputBase-root,.smallSelectDTIcon .MuiInputBase-root,
.smallSelectDT .MuiOutlinedInput-input
{
    height:17px !important;
}
:is(.smallSelectDT,.smallSelectDTIcon,.smallSelectDT2 ) .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"],
:is(.smallSelectDTIcon ) .MuiOutlinedInput-inputMarginDense
 {
    padding:0px !important;
    height:17px !important;
    font-size:12px;
    width:auto !important;
    background:var(--primary-main) !important;
    /*border: 1px solid var(--primary-dark);*/
}

.smallSelectDT .MuiOutlinedInput-input,.smallSelectDT2 .MuiOutlinedInput-input,.smallSelectDTIcon .MuiOutlinedInput-input{
    color:white !important;
    padding-top:0px !important;
    padding-bottom:0px !important;
}
.smallSelectDTT{
    width:100%;
    min-width: 175px;
    text-align:start;
    margin-top: auto;
    margin-bottom: auto;
}
.smallSelectDTT .MuiFormControl-marginDense {
    margin-top: 4px;
    margin-bottom: 4px;
}
.smallSelectDTT .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
 {
    padding:0px !important;
    height:20px !important;
    font-size:12px;
    width:auto;
    background:#eee;
    /*border: 1px solid var(--primary-dark);*/
}
.smallSelectDTT .MuiOutlinedInput-input{
    color:black !important;
    padding-top:1px !important;
    padding-bottom:1px !important;
}


.line-container {
    width: 200px;
    height: 16px; /* Change height to match your desired container */
    overflow: hidden;
  }
.single-line {
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }

.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 9px !important;
    background: #f1f1f1;
    border-radius: 10px;
    /*box-shadow: 0px 1px 2px grey;*/
    border: none;
}
.smallAutoFiltre .MuiAutocomplete-root{
    border-radius: 10px !important;
}
.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0 !important;
    font-size: 12px;
    height: 10px;
    width:fit-content;
    font-weight: 700;
    z-index: 10000;
}
.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 7px) scale(1) !important;
    color: black !important;
    font-weight: 600;
    font-size: 14px;
}

.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText1 .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.smallAutoFiltre .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
#exp .smallAutoFiltre .MuiFormControl-marginDense {
    margin-top: 3px !important;
}

.smallAuto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0 8px !important;
    font-size: 12px;
    height: 13px;
    font-weight: 700;
}

.smallNote .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense,.smallAuto .MuiOutlinedInput-adornedEnd, .smallText .MuiOutlinedInput-adornedEnd,.smallText1 .MuiOutlinedInput-adornedEnd{
    background: #f8f8f8;
}

.MuiFormControl-root {
    width:100% !important;
}

.smallAuto .MuiInputLabel-outlined.MuiInputLabel-marginDense,.smallText .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: grey;
    font-size: 14px;
    transform: translate(14px,8px) scale(1) !important;
}

.smallText1 .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: grey;
    font-size: 12px;
    transform: translate(14px, 4px) scale(1) !important;
}

.smallAuto .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText1 .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}
.smallAuto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 8px;
    border-radius: 10px;
}

.smallerAuto .MuiOutlinedInput-adornedEnd{
    padding: 2px 3px !important;
    margin: 0;
 }
 .smallerAuto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    font-size: 11px !important;
 }

.smallText .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 29px;
    font-size: 12px;
    font-weight: 600;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
    border-radius: 10px;
}

#topV .smallText .MuiOutlinedInput-inputMarginDense,#vnt .smallText .MuiOutlinedInput-inputMarginDense,
#topC .smallText .MuiOutlinedInput-inputMarginDense,#Noc .smallText .MuiOutlinedInput-inputMarginDense{
    height: 28px !important;}

.smallText .MuiOutlinedInput-root {
    position: relative;
    border-radius: 10px;
}
.form-two-grid-container .smallText .MuiOutlinedInput-inputMarginDense,#container .MuiOutlinedInput-inputMarginDense,
#row-sim .smallText .MuiOutlinedInput-inputMarginDense,#trans .smallText .MuiOutlinedInput-inputMarginDense {
    height: 29px;
    border-radius: 10px;
}
.smallText1 .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 25px;
    font-size: 12px;
    font-weight: 600;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
}
.smallTextTable .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}
.smallTextTable .MuiOutlinedInput-adornedEnd{
    background: #f8f8f8;
}

.smallTextTable .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: grey;
    font-size: 14px;
    transform: translate(14px, 4px) scale(1) !important;
}
.smallTextTable .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}
.smallTextTable .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    background: #eee !important;
    display: grid;
    align-content: center;
    text-align: right !important;
}
.smallAutoProd .MuiOutlinedInput-adornedEnd{
    background: #f8f8f8;
}
.smallAutoProd .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 3px !important;
    /*background: var(--secondary-main);*/
}
.smallAutoProd .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: grey;
    font-size: 14px;
    transform: translate(14px, 4px) scale(1) !important;
}
.smallAutoProd .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}
.smallAutoProd .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
}
.smallElem .MuiFormControl-marginDense {
   margin-bottom:auto  !important;
   margin-top:auto !important;
}
.smallNote .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    width:100%;
    background: #f8f8f8 !important;
}
.smallNote .MuiOutlinedInput-root {
    position: relative;
    border-radius: 10px;
}

.smallNote .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: grey;
    font-size: 14px;
}

.checkbox{width:auto;margin-right: 0 !important;margin-left:20px;}
.checkbox .Mui-checked{
    color:green !important;
  }
.checkbox .MuiButtonBase-root{
    color: red;
  }
.selectI{
    width:150px;
    min-height: 100%;
    margin-left: 3px !important;
  }
.selectI .MuiOutlinedInput-notchedOutline{
    border-radius:25px;
}
.MuiDivider-root{
    color:gray;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.tooltip{  position: relative;}
.tooltip span{  display: none;}
.tooltip:hover span{  display: block;  position: absolute; 
     top: 1em;  left: 2em;  padding: 0.2em 0.6em;  border: 1px solid #996633;  background-color: #FFFF66;  color: #000000;}

.barCodeImpSmall{
    height: 2cm;
    width:4cm;
    padding-top: 0.2cm;
    padding-bottom: auto;
    padding-left: 0.2cm;
    font-size: 10px;
}
.barCodeN{
    text-align: center;
    justify-content: center;
}
#rowImprimerEtq{
    display: grid;
    grid-template-columns: auto 110px;
    align-items: center;
}
#priceEtiq b{
    font-size: 12px;
    justify-self: end;
}
.gridEtiq{
    display: grid;
    grid-template-columns: 60% 40%;
}

.InputNbrEditable{
    width:100%;
}
.hHjhh {
    font-weight: 400;
    margin: 5px;
    width: 22px !important;
    max-width: 22px !important;
    min-width: 22px !important;
    cursor: grab;
}

.disabledDiv{
 opacity: 0.5;
}
.centerContent{
    justify-content: center;
}
.tag{
    display: flex;
    border: none;
    align-content: center;
    width: fit-content;
    box-shadow: 1px 1px 1px grey;
    background: var(--secondary-main);
    border-radius: 2px;
    height: 24px;
    font-size: 13px;
    margin: 5px 5px;
}
.tag-name{
    height: 24px;
    padding: 0 5px;
    border: none;
    border-radius: 0;
}
.tag-elem{
    display: flex;
    height: 24px;
    width:fit-content;
    align-items: center;
    background:inherit;
    padding: 0 5px;
}
.tag-name:hover{
   background: var(--primary-main) !important;
   color:white !important;
}
.tag:hover{
   background: var(--primary-dark) !important;
   color:white !important;
}

.fieldInf{
    font-weight: 600;
    min-width: 65px;
    height: fit-content;
    min-height: 18px;
    max-height: 29px;
    padding: 0 5px;
    background-color: #d9d9d9;
    border-radius: 5px;
    border:1px solid grey;
    outline: none;
    margin-right: 2px;
    margin-bottom: 5px;
    width:100%;
    font-size: 12px;
    text-align: start;
    line-height: 1.1;
    overflow: hidden;
    min-height: 27px;
}
.fieldInf .inf{
    display:inline-block;
    box-shadow:none;
    background: inherit;
    height:16px;
    min-height:16px;
    max-height:16px;
    width: fit-content;
}
.fieldInf legend{
    padding: 1px 2px 0px 2px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color:rgb(110, 110, 110);
    background: inherit;
}

.fieldInfParam{
    font-weight: 600;
    min-width: 65px;
    height: fit-content;
    min-height: 18px;
    max-height: 29px;
    padding: 0 5px;
    background-color: #d9d9d9;
    border-radius: 5px;
    border:1px solid grey;
    outline: none;
    margin-right: 2px;
    margin-bottom: 5px;
    width:100%;
    font-size: 12px;
    text-align: start;
    line-height: 1.1;
    overflow: hidden;
    /*min-height: 27px;*/
    align-items: center;
    display: grid;
}
.fieldInfParam .inf{
    display:inline-block;
    box-shadow:none;
    background: inherit;
    height:16px;
    min-height:16px;
    max-height:16px;
    width: fit-content;
}
.fieldInfParam legend{
    padding: 1px 2px 0px 2px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color:rgb(110, 110, 110);
    background: inherit;
}

/* scrollbar 
/* width 
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track 
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle 
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover 
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }*/

 
/*costum tooltip*/
 .tooltip {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltip:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltip:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    /*bottom: 108%;*/
    color: white;
    content: attr(title);
    left: 20%;
    bottom: -130%;
    padding: 4px 2px;
    position: absolute;
    z-index: 98;
    width: 160px;
    white-space:normal !important

  }

/*costum tooltip*/
 .tooltipS {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltipS:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltipS:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    /*bottom: 108%;*/
    color: white;
    content: attr(data-text);
    left: -150px;
    bottom: 0%;
    padding: 4px 2px;
    position: absolute;
    z-index:1000;
    width: 160px;
    white-space:normal !important

  }
/*costum tooltip*/
 .tooltip1 {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltip1:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltip1:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    /*bottom: 108%;*/
    color: white;
    content: attr(title);
    left: 20%;
    padding: 4px 5px;
    position: absolute;
    z-index: 98;
    width:fit-content;
    white-space:normal !important;
    cursor: default;

  }

  /*costum tooltip*/
 .tooltipU {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltipU:hover:after {
    font-weight:normal;
    font-size:9.5px ;
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    color: white;
    content: attr(data-tooltip);
    left: 20%;
    padding: 4px 5px;
    bottom: -190%;
    position: absolute;
    z-index: 1000;
    width:220px;
    white-space:normal !important;
    cursor: default;
    text-align: center;
  }
 
/* .tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 100%;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 98;
  }*/
  .heading{
    height: 25px;
    width: 100%;
    background-color: var(--secondary-main);
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
  
  /* Button style */
  .bbutton {
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #2196f3;
    box-shadow: 0 0 4px #999;
    outline: none;
  }
.smallSelectProd{
    width:100%;
    min-width:250px !important;
    text-align:center;
    height:16px;
}
.smallSelectProd .MuiInputBase-root{
    height:17px;
}
.smallSelectProd .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
 {
    padding:0px !important;
    height:17px !important;
    font-size:12px;
    width:auto;
    background:var(--primary-main);
    /*border: 1px solid var(--primary-dark);*/
}
.smallSelectProd .MuiOutlinedInput-input{
    color:white !important;
    padding-top:1px !important;
    padding-bottom:1px !important;
}
.numberKeyboard{
    display: grid;
    grid-template-columns: repeat(3,45px);
    grid-template-rows: repeat(3,50px);
    grid-gap: 35px;
    width:fit-content;
    margin: auto;
    margin-top: 15px;
}
.zeroKeyboard{
    display: grid;
    grid-template-columns: repeat(3,45px);
    width:fit-content;
    grid-gap: 35px;
    margin: auto;
    margin-top: 35px;
}

.largeText .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height:55px;
    font-size: 35px;
    font-weight: 600;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
}
.largeText .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    font-size: 20px;
    transform: translate(14px, 18px) scale(1) !important;
}
.largeText .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 16px;
    transform: translate(14px, -9px) scale(0.90) !important;
    font-weight: 600;
}
.head-check .MuiCheckbox-root {
    color: rgba(203, 199, 199, 0.54);
}
.head-check .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -7.5px;
    margin-right: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
 
.table-inv .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 4.5px !important;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.staticsHead{
    display: grid;
    justify-content: center;
    grid-gap:7px;
    text-align: center;
    width: 100%;
    font-family: "Varela Round", sans-serif;
}
.staticsHead h2{
    font-size: 28px;
    color:black;
    font-weight: 500;
}
.staticsHead p{
    color: var(--primary-main);
}


.staticsHead{
    display: grid;
    justify-content: center;
    grid-gap:7px;
    text-align: center;
    width: 100%;
    font-family: "Varela Round", sans-serif;
}
.staticsHead p{
    color: var(--primary-main);
}

#loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 15px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #cbced0;
    border-radius: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #b9bbbb;
  }

.row-expenderr{padding:16px 16px;background-color:#C7C7C7;}

.row-expenderr table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
.row-expenderr thead th {
   background-color: gray;
   color: white;
   padding: 4px;
   top: 0;
   position: sticky;
  }
.row-expenderr th,.row-expenderr td {
    border: 1px solid #ccc7c7;
    text-align: center;
    font-weight: bold;
  }
.row-expenderr td{
    text-align: center;
}
.row-expenderr #prod {
  text-align: start !important;
  padding-left: 15px;
}
.row-expenderr .numbers{
  text-align: end !important;
  padding-right: 10px;
}
.row-expenderr #icon-p{
    text-align: right;
}
.row-expenderr tr {background:white !important;height: 20px;}

.oneIcons{grid-template-columns:25px}
.twoIcons{grid-template-columns:repeat(2,25px)}
.threeIcons{grid-template-columns:repeat(3,25px)}
.fourIcons{grid-template-columns:repeat(4,25px)}
.fiveIcons{grid-template-columns:repeat(5,25px)}

.updQte{
    width:60px;
    min-width:60px;
    max-width:60px;
    text-align: right;
    padding-right: 10px;
}
.updMtn{
    width:80px;
    min-width:80px;
    max-width:80px;
    text-align: right;
    padding-right: 10px;
}



/* remove arrow from input numbers Chrome, Safari, Edge, Opera */
.removeArrow::-webkit-outer-spin-button,
.removeArrow::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 ;
}

/*remove arrow from input numbers Firefox */
.removeArrow[type=number] {
  -moz-appearance: textfield;
}

.addGap5{
    gap:5px
}

.shareFlex{
    display: flex;
    gap:5px;
    margin: 5px;
}

.shareIcons{
    background: #1682a5;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.tab-filtre{display: flex;grid-gap:5px;float: right;margin: 5px;}

#exp-DT .sc-hKgJUU:first-child {border-right: none !important;margin-right: 0px !important;}

#fieldSet{width: 98%;background-color: #e6e6e6;border-radius: 10px;padding: 8px;margin:10px auto 10px auto ;}

#div-pwd{margin-top:  10px;}

/*datatable row expander class*/
.rdt_ExpanderRow{
  overflow: auto;
}

@media only screen and (min-width: 150px) and (max-width: 450px) {
.grid2, .grid3, .grid4{ grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
.smallAuto .MuiInputLabel-outlined.MuiInputLabel-marginDense,.smallText .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 11px;
    white-space: pre-wrap !important;
}
}

.DialogTitle{display: flex;grid-gap: 10px;align-items:center ;}

@media only screen and (min-width: 150px) and (max-width: 650px) {
.grid4,.grid4-1{ grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
.button-flex-wrapper-chg{margin-bottom: 5px;margin-top: 10px;grid-gap: 10px !important;}
}
@media only screen and (min-width: 150px) and (max-width: 1150px) {
    .fieldInfParam{max-height: 39px;height: 39px;}
    .tab-filtre{display: flex;margin-left: 5px;margin-right:auto;}
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
    .nodata .notFound{width:200px;height:200px; display: inline;}
    .nodata .notAllowed{width:200px;height:207px; display: inline;margin-bottom:20px;padding:20px 30px}
    .nodata .message{color:gray; padding:0; font-size: 22px; font-family: 'Signika', sans-serif;}
    .fieldsetStock { margin: 0px 0px 30px 0px;}
}
@media only screen and (max-width: 550px) {
    .selectI{ margin-top: 3px !important;}
    .table-header .search { margin-top: 5px; }
}