#role { padding-top: 10px; padding-bottom:10px}
#role #row-1 {  grid-template-columns:200px auto;grid-gap:25px}

#priv #row-1{grid-template-columns: 80% auto;display: grid;align-items: center; }

.param .gfHBVC div:first-child{
  white-space: pre-wrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
#usr #row-1{display:grid;grid-template-columns: 80% auto;grid-gap: 10px;align-items: center;}
#usr-add{display: grid;grid-template-columns:49% 49%;grid-gap:10px}
.user-grid{display: grid;grid-template-columns:48% 48%;grid-gap:5px;align-items: center !important;}
#div-usr-hist{max-height: 400px;overflow: auto;}

#stk-usr{height: fit-content;overflow-y: auto;margin-top: 20px;max-height: 250px;}

#fieldSet #prv-item{display: flex;grid-gap: 10px;align-items: center;font-size: 13px;}

#params .MuiTabPanel-root {padding: 0px;}
#params .MuiTab-root {padding: 6px 25%;max-width:400px;}
#params .MuiTabs-indicator{background-color: var(--primary-main) !important;}
#params .MuiTab-textColorInherit.Mui-selected {opacity: 1;color:var(--primary-main);}
#params .css-1kt0o6l {border-bottom:none;}
#params .PrivateTabIndicator-colorSecondary-148 {background-color: var(--primary-main);}
#param-log{width:80%;margin: auto;text-align: center;}
#param-mag{width:80%;display: grid;grid-template-columns:49% 49% ;grid-gap: 25px;margin: auto;}
#param-mag #param-edit,#param-mag #param-noedit{border:2px solid #585858;border-radius: 12px;background-color: #c7c7c7;
  white-space:pre-wrap !important;padding:15px;display: grid;align-items: center !important;}
#param-mag #param-noedit #elem{display: flex;grid-gap: 10px;font-size: 13px;align-items: center;margin-bottom: 15px;}
#param-mag #param-edit #elem{display: grid;grid-template-columns: 5% 18% 72%;grid-gap: 10px;font-size: 13px;align-items: center;
  margin-bottom: 10px;
}

.content-editable-param {min-height: 22px;min-width: 100%;color:unset;padding: 0px 5px 0px 5px;width:auto;height: auto;overflow: hidden;
  white-space: pre-line;text-overflow: ellipsis;border: none;box-sizing: border-box;display: inline-grid;align-content: center;
  border-radius: 5px; text-align: start !important;margin-top:0px !important;background: url('../img/edit.png') no-repeat right;
  background-size:12px;background-color: white;
} 
.content-editable-param:focus { background-color: var(--primary-dark);color: white;border: 1px solid var(--primary-dark);
  border-radius: 5px; outline: 0;
}

#div-param-logo{background-color: #eee;display: flex;grid-gap: 10px;padding: 8px;align-items: center;font-size: 14px;color: gray;
               border-radius: 10px;}


@media only screen and (min-width: 150px) and (max-width: 850px) {
    #priv #row-1{grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #role #row-1 {  grid-template-columns:repeat(auto-fit, minmax(160px,1fr));justify-content: center;grid-gap: 15px;}
    #div-param-logo{display: grid;grid-template-columns:repeat(auto-fit, minmax(160px,1fr));}
  }
@media only screen and (min-width: 150px) and (max-width: 550px) {
    #usr #row-1, #usr-add{display:grid;grid-template-columns:repeat(auto-fit, minmax(180px,1fr)) !important;text-align: center;}
    #param-mag{display:grid;grid-template-columns:repeat(auto-fit, minmax(100%,1fr)) !important;text-align: center;}
   
  }