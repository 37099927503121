
#prod #row-1 {grid-template-columns:45% 30% auto;grid-gap:5px}
#prod #row-2 { grid-template-columns:25% 30% 20% auto;grid-gap:5px }
#prod #row-3 { grid-template-columns:30% 30% auto;grid-gap:5px }
#prod #row-3-1 { grid-template-columns:50% 49.5% ;grid-gap:5px }
#prod #row-2 #btn{ width:100%;height: 100%; padding:7px}

#storeDiv #inside{ height:100%;padding:0; font-size:11px;}
#storeDiv #inside ul{ text-decoration:none;list-style-type: none !important;}
#storeDiv #inside #achat{  position: relative; top:20px; left:15px;list-style-type: none !important; width:55px;}
#storeDiv #inside #vente{  position: relative; top:43px; left:98px;list-style-type: none !important; width:55px; text-align:center !important;}
#storeDiv #inside #stock{  position: relative; top:45px; left:41px;list-style-type: none !important; width:90px; border-radius:6px;font-size: 15px !important;}
#storeDiv #inside p{font-weight:550}

#vente div { margin:0 0 5px 0}
#achat #row-1 { grid-template-columns: 20px auto; align-items: center; text-align: center;}
#stock #row-1 { grid-template-columns: auto; align-items: center; text-align: center;}
.paperProd{
    margin: 5px 3px;
    width:170px;
    height:160px;
    background:white !important;
    border-radius:10px
}
.paperProd #row-1{
    grid-template-columns: auto auto; text-align:center; margin:0;
}
.paperProd #creator{display:grid;grid-template-columns: 86px auto; margin:3px 0;; padding:0 2px}
.paperProd #creator #a{justify-content:left;padding:0;text-align:center; overflow:hidden}
.paperProd #creator #b{text-align:right;justify-content:right; justify-items:flex-end;padding:0}
.paperProd #creator #a p,.paperProd #creator #b p{font-size:12px !important;font-weight:550 !important}

#ref_prod{width: 85%;margin-top:9px;margin-left: auto;margin-right: auto;}
#ref_prod #row-1{ grid-gap: 10px; grid-template-columns:70% 30%;  text-align: center ;margin: 5px;margin-right: 0%;margin-left: auto;}

#stat-prod{width: 95%;margin-top:9px;margin-left: auto;margin-right: auto;}
#stat-prod #row-1{  grid-gap: 10px; grid-template-columns: 49% 50%;margin-bottom: 5px;display: grid;}
#stat-prod #row-2{  grid-gap: 10px; grid-template-columns:49% 50%;display: grid;}

#prod-info{margin-top: 10px; width:84%;margin-left:auto;margin-right: auto;background-color: var(--secondary-light);padding: 10px;}
#prod-prop{ margin-bottom: 6px;grid-gap: 10px; grid-template-columns: 49% 49%;display: grid;}
#prod-err{ margin-top: 10px; width:84%;margin-left:auto;margin-right: auto;background-color:#C7C7C7;padding: 10px;font-weight: bold;
           text-align: center;border-radius: 10px;}
#prod-expended{
 width:95%;margin-left:auto;margin-right: auto;background-color: var(--secondary-light);padding: 10px;display: flex;
  margin-bottom: 5px;
}
#prod-expended #prod-expended-info{
  width:280px ;margin-left: auto;margin-right: auto;align-items: center;display: flex;grid-gap: 5px
}
#cli .hHSTiF {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 0;
}
.MuiAccordionDetails-root{
  display: block !important;
}
#cat-dialog #row-1{
 display: grid; grid-gap: 5px; grid-template-columns:55% 45%;  text-align: center ;
}
#cat-div-icons{display: grid; grid-gap: 5px; grid-template-columns:60% 39%; }
.contBtn {
  display: flex;
  justify-content: center;
}

.onglets {
  width: 250px;
  height: 50px;
  background: rgb(112, 110, 25);
  color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #333;
}
.container {
  width: 500px;
  height: 600px;
  text-align: justify;
  border: 1px solid #333;
  border-top: none;
}
.container p {
  padding: 40px;
  margin: 0;
}
.active {
  background: rgb(248, 248, 248);
  color: #333;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  text-align: center;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color:var(--primary-main) !important;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
}
#iconsProd #row-3 {grid-gap:2px;display: flex;}
.iconsGrid {  grid-template-columns:46% 52%; grid-gap: 2px;display: grid;align-items: center;}
 
#rac #row-1 {  grid-template-columns:20% 32% 15% 20% 12%; grid-gap: 5px;align-items: center !important;}
#rac .MuiFormControl-marginDense {
  margin-top: 4px;
  margin-bottom: 4px;
}
.shortcut .MuiOutlinedInput-input {
  padding: 0px 4px;
  background-color: var(--primary-main);
  color: #f1f1f1;
  border-radius: 5px;
  font-size: 13px;
}
#div-tabs{grid-template-columns:55% auto; grid-gap: 5px;align-items: center !important;display: grid;}
#div-tabs #table-d,#div-tabs #table-g{height: 100%;}
#up-check{margin-left: 8px;visibility: hidden;}
#tab-head{background-color: #9e9e9e;border:1px solid white;width: 97%;height:25px;
          margin: auto;color: white;font-size: 18px;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
         }
#tab-head p{text-align: center}
#table-scroll .sc-kfzBvY{
  min-height:400px !important;
  max-height:400px !important;
}
#table-scroll .rdt_Table{
  max-height:380px !important;
  overflow-y: auto;
}
#table-scrollC .rdt_Table{
  max-height:319px !important;
  overflow-y: auto;
}
.row-expenderr #comments{margin-left: auto;margin-right: auto;width:98%;padding:5px;display:flex;flex-wrap: wrap;
  color:black !important;align-items: center !important;height: fit-content !important;grid-gap:10px;justify-content: center;}
.row-expenderr #comments .note-container{max-width: 500px;width:100%;}
#mtn-total{width:250px;display:flex;grid-gap: 8px;margin:5px auto;background-color:#C7C7C7;padding:10px;border-radius: 10px;
           justify-content: center;align-items: center;}
#form-ajust{width:95%;margin-left: auto;margin-right: auto;}
#row-sim{display: grid;grid-template-columns:45% 54% ;grid-gap: 5px;}

#stat-pram #row-1{display: grid;grid-template-columns:12% 27% 15% 15% 15% 15% ;grid-gap: 5px;}
#stat-pram #row-2{display: grid;grid-template-columns:23% 31% 23% 23% ;grid-gap: 5px;}
.logos{width:50px;text-align: center !important;height:20px;}

.globalHead-cap{width:98%;display: grid;grid-template-columns:30% 2% 30% 2% 30%;margin:5px auto 5px auto;grid-gap: 5px;
                background-color: #C7C7C7;border-radius: 10px;padding: 3px;justify-content:center ;}
#globalHeadChild-cap{display: flex;align-items: center; width: 100%; grid-gap:8px;justify-content: center;}
.ChildHead-cap{display: grid;grid-gap:7px;  font-family: "Varela Round", sans-serif;}
.ChildHead-cap h2{ font-size: 20px; color:black; font-weight: 500;}
.ChildHead-cap p{color: var(--primary-main);font-size: 16px;font-weight: 500;}
#prd-name .gfHBVC div:first-child {white-space:pre-wrap !important ;overflow: hidden;text-overflow: ellipsis;}

#prdHisto .MuiCard-root {overflow:visible; }
#prdHisto .react-tabs{border: 1px solid #eee;overflow: auto;}
#prdHisto .react-tabs__tab-list{border-bottom: 1px solid #eee;background-color:#e6e6e6;;}
#prdHisto .react-tabs__tab--selected{background: #e6e6e6;border:var(--secondary-main);
    border-bottom: 3px solid var(--primary-dark) !important;
      color: var(--primary-dark) !important
    }
#prdHisto #prdHisto-info , #inv-det-infos{width: 100%;background-color: #e6e6e6;border-radius: 10px;justify-content: center;
    font-size: 12px;padding:8px;display: grid;grid-template-columns: 30% 20% 25% 20%;align-items: center;grid-gap: 5px;}
#inv-det-infos{width:98% !important;margin: auto;}
#inv-det-infos-1{width: 98%;background-color: #e6e6e6;border-radius: 10px;justify-content: center;font-size: 12px;padding:8px;
  display: grid;grid-template-columns:25% 70%;align-items: center;grid-gap: 25px;margin: auto;}
#prdHisto #prdHisto-info #item , #inv-det-infos #item , #inv-det-infos-1 #item{display: flex;grid-gap: 5px;align-items: center;}

#inv-det-infos-1 #item1{display: grid;grid-template-columns: 20% auto;align-items: center;}

#inv-clo-infos{width: 98%;background-color: #e6e6e6;border-radius: 10px;justify-content: center;font-size: 12px;padding:8px;
  display: grid;grid-template-columns:30% 30% 30%;align-items: center;grid-gap: 25px;margin: auto;}
#inv-clo-infos #item{display: flex;grid-gap: 5px;align-items: center;}

#inv-btn-div{width:98%;margin: auto;margin-bottom:12px;display: flex;align-items: center;grid-gap: 8px;}
#inv-clo-ent{width:97%;display: grid;grid-template-columns:20px 60% auto;grid-gap: 10px;align-items: center;margin:10px auto 15px auto;}

#prd-valid-code{width:85%;justify-content: center;background-color: #e6e6e6;border-radius: 10px;margin: 10px auto 0px auto;
padding: 8px;}
#prd-valid-code #row-1{display: grid;grid-template-columns: 67% 30%;grid-gap: 8px;align-items: center;margin-left:auto ;
                       margin-right: auto;}
#prd-valid-code .smallText .MuiOutlinedInput-inputMarginDense {border-radius: 10px;}

#btn-clo-head{display: grid;grid-template-columns: 30% 30%;float: right;justify-content: end;}
#pck-head{display: grid;grid-template-columns: 48% 48% ;align-items: center;grid-gap: 10px;}

#code-prod-info{width: 80%;background-color: #C7C7C7;border-radius:10px ;justify-content: center;display: grid;margin: 10px auto 10px auto;
padding: 6px;}
#code-prod-info #item{display: flex;grid-gap: 5px;align-items: center;margin-bottom: 5px;font-size: 16px;font-weight: 500;}

.detailsContainer {
  padding: 0 12px 10px 12px;
  display: grid;
  max-height: 600px;
}

.detailsGrid {
  display: grid;
  overflow: auto;
  max-width: 600px;
  grid-template-rows: 30px 30px auto;
  align-self: center;
  width: 100%;
  justify-self: center
}

.detailsGrid #error1 {
  display: grid;
}
.zakatContent {
  display: grid;
  grid-template-rows: 50px auto;
  overflow: hidden;
}

.zakatContainer {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.zakatCats {
  display: grid;
  grid-template-rows: auto;
  overflow: auto;
}

.zakatCats #DT {
  overflow: auto;
}

#zakatAddForm {
  height: 100%;
  display: grid;
  grid-template-rows: 35px auto;
  overflow: hidden;
  padding-bottom: 24px;
}


.zakatCat {
  min-width: 300px;
  width: 100%;
  border-collapse: collapse;
  /* Ensures there are no borders between rows */
}

.zakatCat thead {
  background-color: #000;
  /* Black background for the header */
  color: #fff;
  /* White text color for the header */
}

.zakatCat th {
  padding: 8px 12px;
  /* Add padding for better readability */
  text-align: left;
  background: black;
  color: #fff;
}

.zakatCat tr {
  height: 30px;
}

.zakatCat tr:nth-child(even) {
  background-color: #f0f0f0;
  /* Light grey background for even rows */
}

.zakatCat tr:nth-child(odd) {
  background-color: #fff;
  /* White background for odd rows */
}

.zakatCat td {
  padding: 8px;
  /* Add padding for better readability */
  border: 1px solid #ddd;
  /* Optional: Add a border to distinguish the cells */
  text-align: left;
}

.zakatDetail {
  width: 100%;
  padding-top: 16px;
}

.zakatDetail td {
  padding: 8px;
  border-radius: 5px;
}

.zakatDetail .label {
  background-color: #888;
  color: white;
  font-weight: 600;
}

.zakatDetail .value {
  background-color: #eee;
  color: #555;
  font-weight: 600;
  text-align: end;
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
  #prod #row-1,#prod #row-2,#prod #row-3,#prod #row-3-1{ grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
  #ref_prod #row-1{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #stat-prod{width:85%;margin-left: auto;margin-right: auto;}
  #stat-prod #row-1{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #stat-prod #row-2{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #rac #row-1{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #stat-pram #row-1{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #stat-pram #row-2{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  #prdHisto #prdHisto-info{width: 100%;background-color: #e6e6e6;border-radius: 10px;justify-content: center;font-size: 12px;padding:8px;
    display: grid;grid-template-columns:repeat(auto-fit, minmax(160px,1fr));align-items: center;grid-gap: 5px;}
}

@media only screen and (min-width: 150px) and (max-width: 1050px) {
  #div-tabs{grid-gap: 40px;  grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;display: block;}
  #div-tabs #table-d,#div-tabs #table-g{height:fit-content}
  #div-tabs #table-g{height:fit-content;margin-top: 38px;}
  #tab-head p{text-align: center;padding-top:1px;}
  .row-expenderr #comments{grid-template-columns:3% 15% auto;}
}
@media only screen and  (max-width: 1150px) {
  #inv-det-infos-1{display: grid;grid-template-columns:repeat(auto-fit, minmax(200px,1fr)) ;grid-gap: 5px;margin: 0px !important;}
  #btn-clo-head{display: grid;grid-template-columns: repeat(auto-fit, minmax(160px,1fr));float: right;justify-content: end;}
}
@media only screen and (min-width: 150px) and (max-width: 550px) {
  #params .MuiTab-root {padding: 6px 15% !important;max-width:250px !important; }
  #param-mag{grid-gap: 10px;grid-template-columns: repeat(auto-fit, minmax(160px,1fr));text-align: center;}
  div#cloDiv{width:96%;padding:3px;}
  #fieldSet #rac-row-1,#prd-valid-code #row-1,#pck-head,#cat-div-icons{
    display: grid;grid-gap: 10px;grid-template-columns: repeat(auto-fit, minmax(180px,1fr));text-align: center;}
  #cat-div-icons{grid-gap: 5px !important;}
}
@media only screen and (min-width: 150px) and (max-width: 450px) {
  .globalHead-cap{display: flex;flex-wrap: wrap;}
  #row-sim{display: grid;grid-template-columns:45% 52.5%;grid-gap: 5px;}
}


