#borderLinearProgress {
    display:grid;
    align-items: center;
    width: 100%;
    padding-top: 10px;
}
#borderLinearProgress #progress{
    max-width: 300px;
    min-width: 200px;
    width:100%;
}
#borderLinearProgress .MuiLinearProgress-colorPrimary{
    width:100%;
}
#borderLinearProgress .MuiLinearProgress-barColorPrimary{
    background-color: var(--primary-main) !important;
    opacity: 0.7;
}
#borderLinearProgress p {
    justify-content: center;
    color:black;
}
#borderLinearProgress #current{
    width:fit-content;
    padding-top:12px;
}
#borderLinearProgress #percent{
    width:100%;
    justify-content: flex-end;
    font-size: 12px;
}
#borderLinearProgress #max{
    width:100%;
    justify-content: flex-start;
}
#borderLinearProgress #percentage{
    width:100%;
    justify-content: flex-end;
}

#card-dig{display: flex;align-items: center;grid-gap: 5px;width: 100%;;width: 100%;font-weight: 600;}
#det-dig{display: flex;align-items: center;grid-gap: 5px;font-size: 12px;margin-bottom: 8px;width: 100%;font-weight: 600;}