.dtIcons{
    width:22px !important;
    max-width: 22px !important;
    min-width: 22px !important;
}
.dtIcons1{
    width:150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
}
.dtInput{
    border: 0;
    text-decoration: none;
    height: 20px;
    width: 100px;
    margin:auto 2px;
    background: #e0e0e0;
}


#column-1 :hover{
    color:white !important;
  
}
.style{
left:50px;
}
/*
tbody tr:nth-child(even) {background: #CCC}
tbody tr:nth-child(odd) {background: #FFF}*/