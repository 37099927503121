#selectCat{ width: 230px !important;height: 100%;}
#selectUsr{ width: 250px !important;height: 100%;}
#categ{display: flex;grid-template-columns: 45% 45%; grid-gap: 5px;}

.row-expenderr #prod {
  text-align: start !important;
  padding-left: 15px;
}
.row-expenderr .numbers{
  text-align: end !important;
  padding-right: 10px;
}
.row-expenderr #icon-p{
    text-align: right;
}
.row-expenderr tr {background:white !important}

.jrnGrid{
  margin: 3px 0px;
  display: grid;
  grid-template-columns:290px 500px minmax(510px,560px) ;
  width:100%;
  height:calc(100% - 6px );
  gap:5px
}

.jrnList{
  display: block;
  padding: 0px 3px;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 115px);
 /* grid-template-rows: minmax(auto,140px) 37px auto;*/
}
.jrnList #table{
  height: fit-content;
  padding: 0 1px 3px 0;
 /* max-height: 140px;
  overflow-y: auto;*/
}
.jrnList #table #caisTot{
  display: flex;
  align-items: center;
  gap:10px;
  padding-right: 10px;
  font-size: 0.8em;
  font-weight: bold;
  justify-content: flex-end;
}
.jrnList #jrList{
  /*max-height: 100%;*/
  height: -webkit-fill-available;
 /* overflow:auto;*/
}
.jrnList #jrList #loading{
  width:100%;
  min-height: 100px;
  max-width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}
.jrnList #jrList .accSum{
  padding:0 5px 0 0 !important
}
.jrnList #jrList .nodata .notFound{
  width:250px;
  height:250px;
}
.jrnList #jrList .accSum .MuiIconButton-root{
  padding:5px 8px 5px 5px !important
}
.jrnList .accordTitle{
  display:grid;
  width:100%;
  grid-template-columns: 45px auto;
  grid-gap:3px
}
.jrnList .accordTitle .accJour{
  font-size: 12px;
  font-weight: bold;
  height:fit-content;
  align-self: center;
}
.jrnList .accMoney{
  width: 100%;
  display: grid;
  align-items: center;
  text-align: end;
  justify-items: end;
  grid-template-columns: 50% auto;
  grid-gap: 3px;
  font-size: 13px;
  font-weight: bold;
}
.jrnList .accMoney .accMoneyElem{
  width: 100%;
  display: grid;
  align-items: center;
  text-align: end;
  justify-items: end;
  grid-template-columns: 18px auto;
  border: 1px solid green;
  border-radius: 5px;
  color:green;
  background: #ffffed;
  padding-right: 3px;
}
.jrnList .accMoney .accMoneyElem g{
  fill:green;
}
.jrnList .accMoney .accMoneyElemMarge{
  width: 100%;
  display: grid;
  align-items: center;
  text-align: end;
  justify-items: end;
  grid-template-columns: 18px auto;
  border: 1px solid #44a6c6;
  border-radius: 5px;
  background: #f8fcfd;
  color:#2d7f9a;
  padding-right: 3px;
}

 .accDet{
  width:100%
 }
 .accDet .accDetInf{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:2px;
    justify-items: center;
 }
 .accDet .accDetInf .staticsHead {
    width: 80px;
    border-radius: 5px;
    grid-gap:2px;
    background: white;
 }
 .accDet .accDetInf .staticsHead h2{
    font-size: 12px;
 }
 .accDet .accDetInf .staticsHead p{
    font-size: 10px;
 }
 .accDet .detHeader{
    display: flex;
    align-items: center;
 }
 #journalierID {
  width:100%;
  border-collapse: collapse;
  margin-top: 5px;
 }
 #journalierID tr{
  font-size: 12px !important;
  font-weight: 600;
  height: 25px;
  align-items: center;
 }
 #journalierID .icon{
  width: 25px;
 }
 #journalierID .icon svg{
  width: 22px;
  height:22px;
  color:var(--primary-main);
  vertical-align: middle;
  cursor: pointer;
 }
 #journalierID .icon svg:hover{
  filter: brightness(85%);
 }
 #journalierID .owner{
  width: 84px;
  display: inline-flex;
  height: 25px;
  align-items: center;
 }
 #journalierID .owner svg{
  width: 16px;
  height:16px;
  color:black;
  align-items: center;
 }
 #journalierID .date{
  height: 25px;
  width: 50px;
  display: inline-flex;
  grid-template-columns: 20px auto;
  align-items: center;
  align-self: center;
 }
 #journalierID .date svg{
  width: 16px;
  height:16px;
  color:black;
 }
 #journalierID .recette{
  width: 84px;
  height: 25px;
  text-align: right;
  padding-right: 5px;
  display: inline-grid;
  align-items: center;
  justify-items: end;
  grid-template-columns: 20px auto;
 }
 #journalierID .recette svg{
  width: 16px;
  height:16px;
  color:black;
 }

.accDetails{
  padding: 5px 3px !important;
  background: white !important;
}

#selectYM{
  width:100%;
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap:3px
}

.middleSection{
  height: 100%;
  display: grid;
  grid-template-rows: 25px 155px auto;
  gap:3px;
  max-height: calc(100vh - 107px);
  overflow-y: auto;
}
.middleSection2{
  height: 100%;
  display: grid;
  max-height: calc(100vh - 107px);
  overflow-y: auto;
}

.middleSection #recoltDiv{
  background: #eee;
}
.middleSection #sommaireDiv,.middleSection2 #sommaireDiv{
  background: #eee;
}
.endSection{
  height: 100%;
  background: #eee;
  max-height: calc(100vh - 107px);
  overflow-y: auto;
}

#jrnSumTable{
 width:100%;
 border-collapse: collapse;
 background: white;
 border: 1px solid black;
}
#jrnSumTable thead tr{
 background: black;
 color: white;
 font-size: 13px;
}
#jrnSumTable tr{
 font-size: 11px;
 font-weight: 600;
 background: #D8E9F7;
}
#jrnSumTable tr td{
  padding:0px 3px 0px 0px;
 }
#jrnSumTable #infHead #categorie{
 background: var(--primary-dark);
 padding:0 15px;
 width: fit-content;
 height: 100%;
}
#jrnSumTable #infHead{
 height: 22px;
 text-align: center;
 font-size: 14px;
 font-weight: 600;
 color:white
}
#jrnSumTable #infHead svg{
 fill:white
}
#jrnSumTable #inf{
 border-top: 1px solid black;
 border-bottom: 1px solid black;
 background: #fff;
 /*background: #FFF9C4;*/
 /*background: #D5EAE2;*/
}
#jrnSumTable #time{
  padding-left: 3px;
  width:55px
}
#jrnSumTable #prod{
  min-width: 220px;
  max-width: 250px;
  text-align: start;
  padding-left: 10px;
}
#jrnSumTable #qte{
  min-width:35px;
  min-width:55px;
  text-align: end;
}
#jrnSumTable #mtn{
  min-width:35px;
  min-width:55px;
  text-align: end;
}
#jrnSumTable #marge{
  min-width:35px;
  min-width:55px;
  text-align: end;
}
.tikHead {
  display:grid;
  grid-template-columns:35% auto 35%;
  gap:5px;
  padding-top: 0px !important;
  height:20px;
}
.tikHead #tikActions{
  text-align: center;
}
.tikHead div{
  height:20px;
}
.tikHead #date-acc{
  justify-content: end;
  text-align: end;
}
.tikBody{
  grid-template-columns: 200px 70px auto !important;
}

#recolInf > legend{
  display: inline-flex !important;
}
#recolInf{
  display: grid;
  row-gap: 3px;
}

#recolInf #row-1{
  display: grid;
  grid-template-columns: auto auto 120px;
  column-gap: 5px;
}

#recolInf #row-2{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px,1fr));
  column-gap: 3px;
}

#recolInf #row-3{
  display: grid;
  grid-template-columns: auto 80px 90px 110px;
  column-gap: 3px;
  align-items: center;
  margin: 3px 0;
}
#recolInf button{
  margin: 0px;
}
#card-acc > div.MuiCardContent-root > #payTik{
  width: 100%;
  display: grid;
  grid-template-columns: auto 50%;
}
#payTik > div:nth-child(2){
  justify-self: end;
}

#payTik > div:nth-child(1){
  justify-self: start !important;
}

.endSection #card-acc{
  margin: 7px;
  margin-bottom: 15px;
}

.endSection #card-acc #dig-exx{
  height: 23px !important;
  display: grid;
  align-items: center;
  padding-bottom: 0px;
}
:is(#sommaireDiv,.endSection) #loading{
  height: 120px;
  display: grid;
  justify-content: center;
  align-items: center;
}

#jrList .MuiAccordionSummary-root.Mui-expanded{
   background: var(--secondary-main);
}

#dayInfo{
  display: flex;
  gap:10px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
}
#dayInfo div svg{
  cursor: context-menu !important;}

#dayInfo > div:nth-child(1){
  display: flex;
  justify-self: flex-start;
  align-items: center;
  min-width:185px
}
#dayInfo > div:nth-child(2){
  display: flex;
  justify-self: flex-end;
  align-items: center;
}

.endSection #card-acc > div.MuiCardContent-root{
  padding: 5px 12px !important;
}
.endSection #div-entete{
       display: grid; 
       width: 100%; 
       padding-left: 0px;
       padding-bottom: 10px;
}

.endSection #agent-face {
  float: left;
  display: grid!important;
  grid-template-columns: auto auto;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
}

.endSection #mtn-face{
  justify-content: flex-end;
  float: right !important;
  display: flex;
  font-size: 12px;
  grid-gap: 15px;
}

.endSection #mtn-face-1 {
  text-align: right;
  font-weight: bold;
}

.endSection #mtn-face-2 {
  text-align: right;
  color: var(--primary-main);
}
.endSection #tick-div {
  display: grid;
  grid-template-columns: 100px auto;
  margin-right: 6px;
  border: 1px solid gray;
  width: fit-content;
  height: fit-content;
  float: right;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  font-size: 13px;
  padding: 5px;
}
.endSection #total {
  color: var(--primary-main);
}

#recolt .fieldsetTop3{
margin-top: 0;
margin-bottom: 0;
}

.monNote{
  width: 200px;
  min-height: 17px;
  margin-left: 7px;
  display: grid;
  align-items: center;
}
.monNote textarea{
  min-height:25px !important;
  padding: 5px !important;
}
.noteDiv{
  padding:5px;
  justify-content:center;
  width:100%;
  display:flex;
  align-items: center;
}
.monNote #note{
  width: 200px;
  min-height: 27px;
  background: #eee;
  padding: 5px;
  text-align: start;
  border-radius: 5px;
  font-size: 14px;
}
.monNote div{
  display: flex;
}

.endSection .content-editable{
  justify-content: left;
}
#infH #mt #mtt{
  display: grid;
  grid-template-columns: 50% auto;
  gap:5px;
  font-size: 12px;
}
#infH #mtn{
  color: #7c887c;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
}
#infH #mtn{
  color: #00a000;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
}
#infH #mtn g{
  fill: #00a000;
}
#infH #mtn svg{
 justify-self: end;
}
#infH #marge{
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  color: rgb(45, 82, 124);
}

#infH #marge svg{
  justify-self: end;
}

.emptyOrUnpaid{
  color:red;
  font-size: 11px;
  display: inline;
  text-align: center;
}

#dayInfo > div:nth-child(3) > svg{
  cursor: pointer !important;
  vertical-align: bottom;
  margin-left: 5px;
  width: 16px;
  height: 16px;
}
#empty-expender{width:100%;border: none;font-weight: bold;text-align: center;}
#year-picker .MuiOutlinedInput-inputMarginDense {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
#year-picker .MuiFormControl-marginDense {
  margin-top: 6px !important;
  margin-bottom: 4px !important;
}
#year-picker .MuiFormLabel-root {
  color: black;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.00938em;
}
#year-picker .MuiInputBase-input {
  width: 72% !important;
}
#year-picker .MuiOutlinedInput-adornedEnd {
   padding-right: 0px !important;
}
#chg-com #row-1{display: grid;grid-template-columns: 49% 49%;align-items: center;grid-gap: 10px;}

#msl-div{width:99%;margin-left: auto;margin-right: auto;display: grid;grid-template-columns: 58% 40%;grid-gap: 20px;
         margin-bottom: 15px;padding:0px 3px 8px 3px;overflow: auto !important;height:100% !important;}

.flex-msl{width:60%;display:grid;grid-template-columns:180px 180px auto;grid-gap: 5px;align-items: center;
  font-size: 14px;margin-left: 10px;margin-top:12px}
#p-cloture{display: flex;grid-gap: 5px;}
#cloture-mtn{color:var(--primary-main);font-weight: bold;}
#stat-msl{width:100% !important;border-radius: 5px !important;margin-top: 15px !important;min-width: 100% !important; 
          background-color: var(--secondary-light) !important;transform: scale(1) !important;
          display: block !important; height: fit-content !important;}

#table-msl{width:100%;border-collapse: collapse; }
#table-msl #head-msl{background-color: black;color: #D8E9F7;font-size: 11px;width:105px;}
#table-msl #head-msl1{background-color: black;color: #D8E9F7;font-size: 11px;}
#table-msl #cell-msl{color:black;font-size: 10px;text-align:right;margin: 10px;font-weight: bold;height: 14px;padding-right: 4px;}
#table-msl #moy-msl{color:white;font-size: 10px;text-align:right;margin: 10px;font-weight: bold;background-color:var(--primary-main);width:110px;padding-right: 4px;}
#table-msl #moy1-msl{color:yellow;font-size: 10px;text-align:right;margin: 10px;font-weight: bold;background-color:var(--primary-main);padding-right: 4px;}
#tab-cell{font-size: 11px;text-align: center;font-size: bold;padding-right: 4px;color:black !important;}
#tab-chrg{max-height: 180px !important;overflow-y: auto !important;overflow-x: hidden;}
#empty-data{text-align: center;font-weight: bold;font-size: 12px; color: black !important;font-weight: bold !important;}
#empty-data1{text-align: center;font-weight: bold;font-size: 12px;margin-top: 10px !important;color: black !important;font-weight: bold !important;}
#empty-result{text-align: center;font-weight: bold;font-size: 12px;margin-top:10px;margin-bottom: 10px;padding-bottom: 10px;
              color:black !important;}
#total-chrg{background-color:var(--primary-main);min-width: 50px;text-align: right;color:yellow;padding-right: 4px;}
#total-div{width: 100%;display: flex;justify-content: end;font-size: 11px;font-weight: bold;margin-top: 5px;grid-gap: 10px;color: black !important;}
#total-gen{width: 100%;display: flex;justify-content: end;font-size: 11px;font-weight: bold;grid-gap: 10px;margin-top: 2px;}
#marge-desc{width:auto;background-color:var(--secondary-main);color:black;font-size: 14px;justify-content: flex-end;
               text-align: center;font-weight:bold;height:22px;}
#com-usr{width:100% !important;height: fit-content !important;border-radius: 5px !important;margin-top: 15px !important;
        background-color: var(--secondary-light) !important;transform: scale(1);display: block !important;}
#right-div{display:block !important;height: 100% !important;overflow-y:auto !important ;}
#com-usr #row-1{width:99% !important;display:grid;grid-template-columns: 27% 21% 12% 20% 18%;grid-gap:5px;align-items: center;padding: 5px;}
#com-usr #All-row{width:80% !important;margin-left: auto !important;margin-right: auto !important;padding:10px 0 !important;}
#com-usr #ligne{width:99%;display:grid;grid-template-columns: 48% 48% ;grid-gap:5px;align-items: center;padding: 2px;align-items: center;justify-content: center;}
#com-usr #ligneR{width:98.5%;padding:2px 8px 2px 9px;align-items: center !important;justify-content: center !important;}
#btn-com{width:99%;text-align: center;padding-bottom: 5px;}
#com-usr #ligne1{width:98.25%;display:grid;grid-template-columns:20% 20% 16% 21% 21% ;grid-gap:5px;align-items: center;padding: 2px;
                 align-items: center;margin-left: 5px;}
#com-usr #row-1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root,.flex-msl .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px !important;
}

#ligne1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px !important;
}
#com-usr #All-row .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px !important;
}
#com-usr #row-1 .css-xxees4 .MuiOutlinedInput-root {
  padding: 6px;
}
#ligne1 .css-xxees4 .MuiOutlinedInput-root {
  padding: 6px;
}

.titleCharts{text-align: center; color:rgb(65, 63, 63);font-weight:bold !important;margin-left: 15px;}
#div-left{padding-top:0px;height: 100% !important;overflow-y: auto;display: block !important;}
#table-com{padding-bottom: 8px;height:60px ;overflow: auto;max-height: 120px;}
#tab-com{border-collapse: collapse;width: 95%;border: 1px solid black;font-size: 12px;margin-left: auto;margin-right: auto;}
#head-com{background-color: black;color: #D8E9F7;font-size: 12px;}
#tab-com-cell{text-align: center;}

#clo-inf{margin-bottom: 5px;padding-right: 15px !important;padding-left: 15px !important;}
#clo-inf .css-1ws8119-MuiDivider-root {
  margin:0 5px !important;
}
#clo-inf .css-9mgopn-MuiDivider-root {
  margin:5px !important;
}
#clo-inf .MuiDivider-root {
  color: grey;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0px!important; 
  margin-bottom:0px!important;
}
#head-color{color: black !important;}
#SelectCom .MuiInputLabel-outlined {
    font-weight: 600;
    color: grey;
    font-size: 14px;
    transform: translate(14px, 8px) scale(1) !important;
}
#SelectCom .MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}
#SelectCom .MuiInputBase-input{
  background-color: transparent !important;
  font-size: 13px;
  font-weight: 600;
}
#SelectCom .MuiOutlinedInput-root{
  border-radius: 10px;
}

@media only screen and (max-width: 550px) {
  #mtn-lst-exp{margin: 10px 0 10px 5px;}
}
@media only screen and (min-width: 850px) and (max-width: 1115px) {
  #com-usr #ligneR{width:99%;padding:2px 7px 2px 6px;align-items: center !important;justify-content: center !important;}
  .flex-msl{width:70%;grid-gap:5px; grid-template-columns: repeat(auto-fit, max-width(200px)) !important;
    display: grid;margin-top: 20px;
    padding-left: 5px;align-items: center !important;}
}
@media only screen  and (max-width: 850px) {
  #msl-div{width:99%;display: grid;grid-template-columns: repeat(auto-fit, minmax(100%,1fr));grid-gap: 10px;margin-bottom: 15px;padding:8px 3px 3px;height:100%;overflow: auto;}
  #div-left{padding-top:0px;}
  #com-usr #row-1{width:99%;display:grid;grid-template-columns: repeat(auto-fill, minmax(110px,1fr));grid-gap:5px;align-items: center;padding: 5px;
                  justify-content: center;}
  #com-usr #ligne1{width:98.25%;display:grid;grid-template-columns: repeat(auto-fill, minmax(110px,1fr));grid-gap:5px;align-items: center;padding: 2px;
      align-items: center;margin-left: 5px;}
  .flex-msl{grid-gap:5px; grid-template-columns: repeat(auto-fit, minmax(260px,1fr)) !important;display: grid;margin-top: 20px;padding-left: 5px;
    align-items: middle !important;}
  #com-usr #ligneR{width:98%;padding:3px;align-items: center !important;justify-content: center !important;}

}