
.form-two-grid-container {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-gap: 5px;
    padding: 0 10px;
    margin: 0;
}
.note-add{
  padding: 0 8px 0 10px;
}
#iconsAch {  display:grid;}
#iconsAch #row-1 {  grid-template-columns:  20px 20px; grid-gap: 5px;}
 #auto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
  padding: 0px 0px;
  
}
#auto1 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
  padding: 0px 0px;
  
}
#auto1 .MuiFormControl-marginDense {
  margin-top: 0px;
  margin-bottom: 0px;
}
#auto .MuiFormControl-marginDense {
  margin-top: 0px;
  margin-bottom: 0px;
}

#trns #row-1 { grid-gap:3px; grid-template-columns:42% 42% 13%;;align-items: center;margin-bottom:5px;}
#trns #row-2 { grid-gap:12px; grid-template-columns:67% 15% 14%;margin-bottom:5px;}


#trans .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 0px;
  overflow-y: hidden;
}
.impr .gfHBVC div:first-child{
  white-space: pre-wrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width:30px;
}
.impr .egUoM {
  background-color: brown;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  font-size: 10px !important;
  padding: 1px 3px;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: inherit;
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
  #trns #row-1 { grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr)) ;}
  #trns #row-2 { grid-template-columns: repeat(auto-fit, minmax(160px,1fr)) ;}
  .form-two-grid-container{display: grid;grid-template-columns: repeat(auto-fit, minmax(160px,1fr));grid-gap: 5px;justify-content: center;}
}

