#digit-cards{grid-gap: 10px;  grid-template-columns: repeat(auto-fit, minmax(190px,1fr));display: grid;padding: 15px;
             margin-left: auto;margin-right:auto ;width:97%;}
#img-dig{text-align: center;width: 100%;}
#card-dig{display: flex;align-items: center;grid-gap: 5px;width: 100%;}
#det-dig{display: flex;align-items: center;grid-gap: 5px;font-size: 12px;margin-bottom: 8px;}
#div-det{display:grid;grid-template-columns:38% auto; grid-gap: 5px;align-items: center !important;}
#digit-name{text-align: center !important;}
#ticket-dig{display:grid;grid-template-columns:65% 34%; grid-gap: 5px;align-items: center !important;}
#ticket-dig1{display:grid;grid-template-columns:65% 34%; grid-gap: 5px;align-items: center !important;}
#ticket-dig #entet-tick-dig{font-size: 12px;font-weight: bold;}
#ticket-dig1 #entet-tick-dig{font-size: 10px;font-weight: bold;}
#ticket-dig #entet-tick-dig1{font-size: 9px;font-weight: normal;}
#ticket-dig1 #entet-tick-dig1{font-size: 9px;font-weight: normal;}

#ticket-dig #logo_ticket{
  width:85px;
  height:auto
}
#ticket-dig1 #logo_ticket{
  width:55px;
  height:auto
}

#digit-name{text-align: center;}
 
#digCont1{padding: 0px !important; width:168px}
#digCont{padding: 0px !important;}

#digit-inf{width:98%;height: auto;font-size: 12px !important;padding:5px 0;margin: auto;font-size: 11px;font-weight: bold;}
#digit-inf #row{width:100%;display:grid;grid-template-columns:38% 50%;align-items: center;margin-bottom: 3px;font-size: 15px;}
#digit-inf #row1{width:100%;text-align: right;font-weight: bold;font-size: 12px;margin-bottom: 5px;font-weight: bold;}
#digit-inf #code{width: 100%;background-color: black;margin: auto;color: white;font-weight:bold;font-size: 19px;text-align: center;padding: 3px;margin-bottom: 5px;border-radius:3px;}

#digit-inf1 {width:98%;height: auto;font-size: 11px !important;padding:5px 0;margin: auto;font-size: 11px;font-weight: bold;}
#digit-inf1 #row{width:100%;display:grid;grid-template-columns:30% auto;align-items: center;margin-bottom: 3px;font-size: 11px;}
#digit-inf1 #row1{width:100%;text-align: right;font-weight: bold;font-size: 12px;margin-bottom: 5px;font-weight: bold;}
#digit-inf1 #code{width: 100%;background-color: black;margin: auto;color: white;font-weight:bold;font-size: 14px;text-align: center;padding: 3px;margin-bottom: 5px;border-radius:3px;}
#digit-inf1 #digit-name{font-size: 15px; font-weight:600}


#footer-digit{font-size: 12px;text-align: center;margin-top:6px ;}
#footer-digit1{font-size:12px;text-align: center;margin-top:6px ;font-weight:bold;}
#footer-digit2{font-size:9px;text-align: center;margin-top:6px ;font-weight:bold;}

#dig-ex{width: 100%;height:30px;background-color:#eaeaea;text-align: end;margin-top: auto;margin-bottom: auto;display: grid; grid-template-columns: 50% 50%;}
#dig-exx{width: 100%;height:20px;padding:2px;
         background-color:#eaeaea;text-align: end;margin-top: auto;margin-bottom: auto;font-size: 11px;font-weight: bold;}
#print-tick{margin-right: 5px;text-align: left;}
#share-tick{margin-left: 5px;text-align: right;}


#img-at{text-align: center;width: 100%;margin-top: 10px;padding: 5px;}
#div-det-at{display:grid;grid-template-columns:38% auto; grid-gap: 15px;align-items: center !important;margin-top: 15px;}
.globalHead{width:100%;display: grid;grid-template-columns:49% 2% 49%;padding: 15px;margin: auto;}
#globalHeadChild{display: flex;align-items: center; width: 100%; grid-gap: 25px;justify-content: center;}
.ChildHead{display: grid;grid-gap:7px;  font-family: "Varela Round", sans-serif;}
.ChildHead h2{ font-size: 28px; color:grey; font-weight: 500;}
.ChildHead p{color: var(--primary-main);text-align: center;}

#digit-cards .css-46bh2p-MuiCardContent-root {padding: 6px;}
#code-dig{color: var(--primary-main);}
#serial-dig{color:green;}
#acc-div{width:70%;display: block;margin-left: auto;margin-right: auto;}
#card-acc{margin-bottom: 10px;}
#num-acc{font-size: 11px;float: left;display:grid;grid-template-columns:24% 34%;align-items: center !important;}
#agent-face{float: left;display: block !important;font-size: 12px;align-items: center;font-weight:bold ;}
#agent-face-div{text-align: center;width:55px;}
#date-acc{float:right;align-items: center !important;display: flex;grid-gap: 5px;margin-right: 5px;}
#mtn-face{float: right !important;display: flex;font-size: 12px;grid-gap: 15px;}
#mtn-face-1{text-align: right;font-weight: bold;}
#mtn-face-2{text-align: right;;color:var(--primary-main);}
#div-entete{display:grid;grid-template-columns:80% 20%;width:100%;padding-left: 35px;}
#empty-ventes{text-align: center;font-weight: bold;}
#tick-div{display:grid;grid-template-columns:60% auto;margin-right: 6px;
  border: 1px solid gray;width: 180px;height: fit-content;float: right;text-align: center;font-weight: bold;margin-top: 5px;font-size: 13px;padding: 5px;}
#full-ventes{min-height: fit-content;}
#total{color:var(--primary-main) ;}
#montant{color:var(--primary-main) ;margin-left:15px ;}
#tick-mtn{font-weight: bold;}
#foot{display:grid;grid-template-columns:74% 35% !important;width: 90%;margin-left: auto;margin-right: auto;align-items: center;}
#agent-face > svg{
  cursor: context-menu;
  margin: 0 5px;
}

.digit-name{text-align: center;font-weight: bold;}

#prd-img{align-items: center !important;display: grid;grid-template-columns: 15% auto;grid-gap: 5px;}
#prd-img #prd{justify-content: center;display: flex;}

#brnInfos{
  display:grid;
  grid-template-columns: 50% 2% auto;
  gap:10px;border-radius: 10px;
}
#brnInfosAir{
  display:grid;
  grid-template-columns: 100%;
  justify-content: center;
  justify-Items: center;
}

#brnInfosAir .makeStyles-root-100{
  padding: 5px 10% !important;

}
#brnInfosAir #borderLinearProgress #progress{
  max-width: 100%!important;
}

@media only screen and (min-width: 150px) and (max-width: 1050px) {
    #digit-cards{grid-template-columns: repeat(auto-fit, minmax(160px,1fr)) !important;} 
     }
@media only screen and (min-width: 150px) and (max-width: 550px) {
    #div-entete{display:grid;grid-template-columns:50% 20%;padding-left: 0px !important;}
    #tick-mtn{align-items: center;}
    #foot{display:grid;grid-template-columns:45% 45% !important;width: 97%;}

     }
@media only screen and (min-width: 150px) and (max-width: 800px) {
    #brnInfos{grid-template-columns: repeat(auto-fit, minmax(320px,1fr)) !important;} 
    #acc-div{width:80%;display: block;margin-left: auto;margin-right: auto;}
     }