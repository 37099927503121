#vntInfo, #vntForm { padding-top: 5px}
#vntForm{ padding-top: 5px;padding:5px 10px;}
#vntInfo .grid3{ display:grid; grid-template-columns: repeat(3,1fr) !important;}
#addV{
    display: grid;
    grid-gap:10px;
    margin-top: 10px;
}
.ticketRet{
    background: #ffc349;
}
#addVM{
    grid-template-columns: 90px 130px auto;
}
#addS .rdt_Table{
    max-height:300px !important;
    overflow-y: auto;
}
#addS .sc-kfzBvY{
    max-height:150px !important;
  }   
#vntInfo #inf1{
    grid-template-columns: 50px auto; padding-bottom:5px; align-items: center;
}

#vntForm .MuiDivider-root{
    color:gray;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
#vntForm #row1{
    grid-gap:5px;
    grid-template-columns: 160px auto 120px 130px; padding-bottom:5px;
}
#vntForm #row2{
    display: flex;
}
#vntForm #row3{
    grid-gap:5px;
    grid-template-columns: 300px 200px;
    align-items: end;
}
#comptoire{
    display: grid;
    grid-template-columns: 310px auto;
    width:100%;
    height: calc( 100% - 30px);
    grid-gap:0px;
}

.vntGrid{
    display: grid;
    grid-template-columns: auto 276px;
    width:100%;
    grid-gap:5px;
    padding-right:5px
}
.vntGrid2{
    display: grid;
    width:100%;
    grid-gap:5px;
    padding-right:5px
}
:is(.vntGrid,.vntGrid2) svg{
    cursor: pointer;
}
:is(.vntGrid,.vntGrid2) .MuiDivider-root{
    margin:3px 0 !important;
}
#ticketList{
    width:100%;
    height: 100% ;
    grid-template-rows: 210px auto;
    display:grid;
    padding: 5px;
    padding-top: 0px;
}
#ticketList .ticketbuttons{
    text-align: center;
    padding: 10px 0px;
}
.ticketListP{
    background: var(--secondary-light) !important;
    width:100%;
    height:calc(100% - 5px) ;
    margin: auto;
    margin-top: 5px;
    align-self:baseline;
}
#ticketDetail{
    width:100%;
    height:100%;
    display: grid;
    grid-template-rows: 210px auto;

}
#ticketInfo{
    display:grid;
    width:100%;
    height: 100%;
     margin:auto;
    grid-template-columns: 100%;
}
#ticketInfo .fieldsetTop3{
  min-height: 194px;
}
#ticketDet{
    width:100%;
    height: 100%;
    padding: 5px;
    overflow: auto;
}
.ticketDetP{
    background: var(--secondary-light) !important;
    width:100%;
    height: 100%;
    margin: auto;
    padding: 20px 10px 5px 10px;
    display: grid;
    overflow: auto;
}

.digL{
    height:calc( 100% - 4px);
    max-height:calc( 100% - 4px);
    overflow:hidden
}
.digitList {
    width:100%;
    margin: 5px 0px 0px 0px;
    display: grid;
    height: calc( 100% - 92px);
    max-height: calc( 100% - 92px);
    border:1px solid #ddd ;
    display:grid;
    grid-template-rows:20px auto;
    background-color :var(--secondary-light) !important;
}
.digsListGrid{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:5px;
    
}
#vntTabPanel {
  overflow: auto !important;
  padding-bottom: 5px;
}
#vntTabPanel .MuiBox-root{
  padding:8px 4px;
}
#vntTabPanel .digName{
  font-size:13px !important;
  font-weight:600 !important;
}
#digTitle{
    overflow: hidden;
    white-space: nowrap;
    padding:5px;
}
#digPrice{
    width: 100%;
    text-align: left;
    align-items: center;
    /* grid-template-rows: auto 18px; */
    /* grid-gap: 3px; */
    padding: 3px 5px;
    position: relative;
    bottom: 23px;
}
#digPrice p{
    font-size:12px !important;
    font-weight: 600;
    text-align: center;
}
.ticketL{
    width:100%;
    text-align: center;
    text-align: center;
    max-height: 230px;
    overflow: auto;
}
.ticketDetL{
    width:100%;
    max-height: 300px;
    /*min-width: 700px;*/
    overflow-y: auto;
    text-align: center;
    text-align: center;
    overflow-x: auto;
}
.ticketL #table{
    width:98%;
    border-collapse: collapse;
    font-size: 12px;
    background-color: white;
    border: 1px solid grey;
    margin:auto;
}

.ticketDetP #table1{
    width:100%;
    height:fit-content;
    border-collapse: collapse;
    font-size: 12px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 10px;
}
.ticketDetP #table1 tr {
    border: none;
    text-align: center;
    height: 20px;
}
.table1T tr {
    height: 28px !important;
    font-size:14px !important;
}
.ticketDetP #table1 tr:focus {
    outline: none ;
    background-color: rgb(253, 248, 248) ;
    border-radius: 2px ;
    border: 1px solid var(--primary-main) !important;
    color:var(--primary-main) !important;
    font-weight: 600;
    text-align: center;
}
.lastSelectedV {
    outline: none ;
    background-color: rgb(253, 248, 248) ;
    border-radius: 2px ;
    border-bottom: 1px solid white !important;
    border-top: 1px solid white !important;
    color:var(--primary-main) !important;
    font-weight: 600;
    text-align: center;
}
.ticketDetP #table1 tr:hover {
    border: none;
    outline: none ;
    background-color: rgb(253, 248, 248) ;
    border-radius: 2px ;
    color:var(--primary-main) !important;
    font-weight: 600;
    text-align: center;
}

.ticketL #table tr {
    border: 1px solid grey;
    text-align: center;
    height: 20px;
}
.tableT tr {
    height: 25px !important;
    font-size:14px !important;
}
.payedTicket{
    background: #d9ffb0;
}
.ticketL #table tr:focus{
    outline: none;
    background-color: var(--primary-dark) !important;
    border-radius: 2px !important;
    border: 1px solid var(--primary-dark) !important;
    color:white !important;
    font-weight: 600;
    opacity: 1 !important;
}
.ticketL #table tr:hover{
    outline: none;
    background: rgb(185, 122, 119) !important;
    border-radius: 2px !important;
    border: 1px solid var(--primary-dark) !important;
    color:white !important;
}
.lastSelectedT {
    outline: none;
    background-color: rgb(185, 122, 119) !important;
    border-radius: 2px !important;
    font-weight: 600;
    border: none !important;
    color:white !important;
}

.numero{
    width:80px;
    min-width:80px;
    max-width:80px;
    text-align: right;
    padding-right: 10px;
}
.numeroQte{
    width:50px;
    min-width:50px;
    max-width:50px;
    text-align: right;
    padding-right: 10px;
}
.numerot1{
    width:75px;
    min-width:75px;
    max-width:75px;
    text-align: right;
    padding-right: 10px;
}

#circularProgress1{
  display: flex;
  width:auto;
  height: 100px;
  margin: 0px 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  border: 1px solid rgba(128,128,128,0.5); 
}
#circularProgress{
  display: flex;
  width:100%;
  height: 100px;
  align-items: center;
  min-width: 686px;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  border: 1px solid rgba(128,128,128,0.5); 
}
.shortCuts{
    font-size: 11px !important;
    font-weight: 600 !important;
    height: 20px !important;
    margin:0px 0px 5px 5px !important;
}
#vntAddAuto{
    display: grid;
    grid-template-columns: 250px 250px 150px;
    grid-gap:5px
}
#vntAddAuto #inf1{
    grid-template-columns: 40px auto; padding-bottom:5px;
}
#vntForm #inf2{
    display: grid;
    align-items: center;
    width:fit-content;
    grid-template-columns: 200px 160px 5px; grid-column-gap: 20px;
    margin-right: 20px;
}
#vntForm #raccField{
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    max-height: 165px;
    overflow: auto;
    padding-top:5px;
}
#ticketFunc{
    padding: 0 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px,1Fr));
    grid-column-gap:3px;
    justify-content: center;
}
.racBtnLabel{
    font-size: 12px;
    display:flex;
    justify-content: center;
    padding-left: 0px;
    min-height: 21px;
    overflow: hidden; 
    white-space: wrap;
}
.racBtnLabelTouch{
    font-size: 12px;
    display:flex;
    justify-content: center;
    overflow: hidden; 
    white-space: nowrap;
}
.racIcon{
    display: flex;
    align-items:center;
    height:17px;
    grid-gap:3px;
}
.racIcon2{
    display: flex;
    align-items:center;
    grid-gap:3px;
}
.racIconF{
    height:17px;
}
.venteIcons{
    width:22px;
    min-width:22px;
    max-width:22px;
    text-align: end;
    font-size: 5px !important;
}
.venteIcons g{
    fill: #00a000;
  }
.numero .content-editable{
   padding-right:0px;
}

.qteT .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}
.qteT .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 18px;
    font-size: 12px;
    font-weight: 400;
    background: var(--primary-main) !important;
    border-radius: 5px;
    color:white;
    display: grid;
    align-content: center;
    text-align: right !important;
    opacity: 0.5;
}
.qteT .MuiOutlinedInput-inputMarginDense:focus {
    opacity: 1;
}
#timeTv{
    width:45px;
    padding:0px 5px;
}
#prodNameV{
    width:300px;
    min-width:150px;
    padding:0px 5px;
}

#stockNameV{
    width:130px;
    min-width:60px;
    padding:0px 5px;
}
#stockNameV .smallSelectDT{
    min-width:80px;
}

#vntDT .rdt_Table{
    max-height:270px !important;
    overflow-y: auto;
  }
#vntDT .ERnSw,#vntDT .sc-kfzBvY{
    max-height:220px !important;
    min-height:220px !important;
}
#iconsCli #row-1{grid-template-columns:35% 23%; grid-gap:10px;align-items: center !important;display: grid;}

#row{width: 96% !important;margin-right: auto;margin-left: auto;}

.stockWrapper{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

.qteT .MuiOutlinedInput-inputMarginDense::-webkit-inner-spin-button { margin-left: 10px !important; }
#detVBon{
    padding:5px;
    overflow: auto;
}

.paperTotal{
    min-width: 300px;
    background: var(--primary-main) !important;
    padding:8px;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 10px;
    border-radius: 10px;
}
.headButtons{
    display: grid;
    grid-template-columns: auto 50px;
    height:30px;
}
.total,.totalPage{
    font-weight: 600;
    font-size: 13px;
    color: white;
}
.total{
    text-align: end;
    padding-right: 5px;
}
.totalPage{
    text-align: start;
    padding-left: 5px;
}
.suggestedPrices{
    max-width: 500px;
    flex-wrap: wrap;
    padding: 0 10px;
    margin: auto;
    display: flex;
    justify-content: center;
}
#addAirForm .qte{
    display:grid;
    grid-template-columns: auto 67px;
    margin: auto;
    grid-gap: 5px;
    padding: 10px;
    margin: 15px 0px;
}
.tickBtn{
    width:245px;
}
.ticket .nClient{
  text-align: start;
  padding-left: 5px;
}
#try p{margin:0;}
#try #binars{margin:0; cursor: pointer;justify-content:flex-end;align-items: center; display: flex;
}

#pdetTable #prod{
    text-align: start !important;
    padding-left: 15px;
}

#pdetTable{padding:16px 16px;background-color:var(--secondary-main);}
#categ{display: flex;grid-template-columns: 45% 45%; grid-gap: 5px;}

#pdetTable table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
#pdetTable thead th {
   background-color: gray;
   color: white;
   padding: 4px;
   top: 0;
   position: sticky;
  }
#pdetTable th,#pdetTable td {
    border: 1px solid #ccc7c7;
    text-align: center;
    font-weight: 600;
  }
#pdetTable td{
    text-align: center;
}
#pdetTable #icon-p{
    text-align: right;
}
#pdetTable tr {background:white !important}

.digsListGrid #prodImg{
    width:100%;
    min-width:100%;
    min-height:100%;
    max-height:125px;
    height: 100%;
    cursor: pointer;
}

#catFilters{
    margin-top: 8px;
    justify-items: center;
    text-align: center;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}
#catFilters img{
    margin: 2px 1px;
    opacity: 0.5;
}
#catFilters img:hover, #catFilters img:focus{
    opacity: 1;
}
.addOp{
    opacity: 1 !important; 
}
#verfifyCode{
    text-align: center;
    display: flex;
    gap:5px
}
#topUp{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
#verifiedInfo{
    text-align: center;
    display: grid;
    grid-template-columns: 150px auto auto auto;
    gap:5px
}

.packGrid{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    justify-content: center;
}
#cli .hHSTiF {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 0;
  }
#cliHist .rdt_ExpanderRow{
  overflow: auto !important;
}
#cliInfo #row-1{
    grid-template-columns: minmax(110px,200px) repeat(auto-fit, minmax(110px,1fr));
}
#cliInfo #row-2{
    grid-template-columns: repeat(auto-fit, minmax(130px,1fr));
}
.row-expenderr #info-expend{width:80%;display: grid;grid-template-columns: 30% 30% 26%;grid-gap: 70px;align-items: center;
                            justify-content: center;margin: auto;}
.row-expenderr #info-expend #item{display: flex;grid-gap: 15px;color: black;font-weight: bold;align-items: center;}

#fieldSet #row-hstCl{display: grid;grid-template-columns: 30% 22% 22% 22%;grid-gap: 10px;align-items: center;}
#fieldSet #row-hstCl #item{display: flex;;grid-gap: 5px;align-items: center;font-size: 12px;}

@media only screen and (min-width: 150px) and (max-width: 450px) {
    #row{width: 94% !important;margin-right: auto;margin-left: auto;}
}
@media only screen  and (max-width: 850px) {
    #vntAddAuto{
        grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;
    }
}